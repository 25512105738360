import styled from "styled-components";
import leftImg from "../../assets/images/business/img_01-1.jpg";
import centerImg from "../../assets/images/business/img_02-1.jpg";
import rightImg from "../../assets/images/business/img_03-1.jpg";
import MultiA1 from "../../assets/images/business/multi_a_1.jpg";
import MultiA2 from "../../assets/images/business/multi_a_2.jpg";
import MultiA3 from "../../assets/images/business/multi_a_3.jpg";
import MultiA4 from "../../assets/images/business/multi_a_4.jpg";
import MultiA5 from "../../assets/images/business/multi_a_5.jpg";
import WallA1 from "../../assets/images/business/wall_a_1.jpg";
import WallA2 from "../../assets/images/business/wall_a_2.jpg";
import WallA3 from "../../assets/images/business/wall_a_3.jpg";
import WallA4 from "../../assets/images/business/wall_a_4.jpg";
import WallA5 from "../../assets/images/business/wall_a_5.jpg";
import SoundB1 from "../../assets/images/business/sound_b_1.jpg";
import SoundB2 from "../../assets/images/business/sound_b_2.jpg";
import SoundB3 from "../../assets/images/business/sound_b_3.jpg";
import SoundB4 from "../../assets/images/business/sound_b_4.jpg";
import SoundB5 from "../../assets/images/business/sound_b_5.jpg";

import ProjectB1 from "../../assets/images/business/project_b_1.jpg";
import ProjectB2 from "../../assets/images/business/project_b_2.jpg";
import ProjectB3 from "../../assets/images/business/project_b_3.jpg";
import ProjectB4 from "../../assets/images/business/project_b_4.jpg";
import ProjectB5 from "../../assets/images/business/project_b_5.jpg";

import PreventC1 from "../../assets/images/business/prevent_c_1.jpg";
import PreventC2 from "../../assets/images/business/prevent_c_2.jpg";
import PreventC3 from "../../assets/images/business/prevent_c_3.jpg";
import PreventC4 from "../../assets/images/business/prevent_c_4.jpg";
import PreventC5 from "../../assets/images/business/prevent_c_5.jpg";

import QuarantineC1 from "../../assets/images/business/quarantine_c_1.jpg";
import QuarantineC2 from "../../assets/images/business/quarantine_c_2.jpg";
import QuarantineC3 from "../../assets/images/business/quarantine_c_3.jpg";
import QuarantineC4 from "../../assets/images/business/quarantine_c_4.jpg";
import QuarantineC5 from "../../assets/images/business/quarantine_c_1.jpg";

const BizSubList = [
  {
    order_index: 1,
    order: 1,
    title: "행사용품 렌탈",
    mainSrc: MultiA5,
    imgList: [MultiA1, MultiA2, MultiA3, MultiA4],
  },
  {
    order_index: 2,
    order: 1,
    title: "포토월 아이디어",
    mainSrc: WallA1,
    imgList: [WallA2, WallA3, WallA4, WallA5],
  },
  {
    order_index: 1,
    order: 2,
    title: "음향&조명 시스템",
    mainSrc: SoundB1,
    imgList: [SoundB2, SoundB3, SoundB4, SoundB5],
  },
  {
    order_index: 2,
    order: 2,
    title: "영상 시스템",
    mainSrc: ProjectB1,
    imgList: [ProjectB2, ProjectB3, ProjectB4, ProjectB5],
  },
  {
    order_index: 1,
    order: 3,
    title: "축제 방역",
    mainSrc: PreventC1,
    imgList: [PreventC2, PreventC3, PreventC4, PreventC5],
  },
  {
    order_index: 2,
    order: 3,
    title: "공기살균기",
    mainSrc: QuarantineC1,
    imgList: [QuarantineC2, QuarantineC3, QuarantineC4, QuarantineC5],
  },
];

const BizTechMobile = () => {
  return (
    <div className={"bg-[#000]"}>
      <article className={"pb-[20px]"}>
        <BizItem
          order={1}
          title={"멀티 시스템"}
          summary={
            "행사를 더 품격있게 서포트해주는 중요한 멀티 시스템을 제안합니다."
          }
          mainImg={leftImg}
          imgList={BizSubList.filter((e) => e.order === 1)}
        />
      </article>
      <article className={"pb-[20px]"}>
        <BizItem
          order={2}
          title={"음향 / 조명 / 영상"}
          summary={
            "행사의 성격에 맞는 하이 퀄리티의 음향, 조명, 영상 시스템을 제공합니다."
          }
          mainImg={centerImg}
          imgList={BizSubList.filter((e) => e.order === 2)}
        />
      </article>
      <article className={"pb-[20px]"}>
        <BizItem
          order={3}
          title={"방역 시스템"}
          summary={
            "코로나 19 시기에 맞춘 철저한 방역 및 안전 시스템을 제공합니다."
          }
          mainImg={rightImg}
          imgList={BizSubList.filter((e) => e.order === 3)}
        />
      </article>
    </div>
  );
};

const BizItem = ({ order, title, summary, mainImg, imgList }) => {
  return (
    <section className={"relative"}>
      <div className={"relative"}>
        <img src={mainImg} />
        <SectionContent $dir={"right"}>
          <p className={"sc-title"}>{title}</p>
          <p className={"sc-summary"}>{summary}</p>
        </SectionContent>
      </div>
      {imgList.map((e, index) => (
        <SectionSubImages key={e.order + "-" + e.order_index}>
          <div className={"ssc-main"}>
            <img src={e.mainSrc} />
          </div>
          <div className={"ssc-sub grid grid-cols-2"}>
            {e.imgList.map((i, iIndex) => (
              <img key={e.order + "-" + e.order_index + "-" + iIndex} src={i} />
            ))}
          </div>
        </SectionSubImages>
      ))}
    </section>
  );
};

const SectionContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 10px;
  color: white;

  > p {
    width: 50%;
    &.sc-title {
      font-family: "SB font";
      font-weight: bold;
      font-size: 20px;
    }
    &.sc-summary {
      font-size: 15px;
    }
  }
`;

const SectionSubImages = styled.div`
  background-color: black;
  display: flex;
  justify-content: start;
  align-items: stretch;
  .ssc-main {
    flex: 1;
    > img {
      height: 100%;
    }
  }
  .ssc-sub {
    flex: 1;
  }
`;

export default BizTechMobile;
