import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import styled, { css } from "styled-components";

const PfPreviewMain = ({ resource }) => {
  const previewList = resource.previewList.read();
  const navi = useNavigate();
  const moveDetailPf = (id) => {
    navi(`/portf/${id}`);
  };
  var settings = {
    infinite: false,
    slidesToShow: 2,
    variableWidth: true,
    // variableHeight: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (isMobile) {
    return (
      <div className={"pf_slide_mobile"}>
        {previewList.map((e, i) => (
          <SliderItem
            key={`iPreview_${e.id}`}
            $isMobile={true}
            onClick={() => moveDetailPf(e.id)}
          >
            <img src={e.thumbnail} width={700} height={500} />
            <p className={"title"}>{e.pfPreviewTitle}</p>
            <p className={"host"}>{e.pfTitle}</p>
          </SliderItem>
        ))}
      </div>
    );
  }
  return (
    <Slider {...settings}>
      {previewList.map((e, i) => (
        <SliderItem key={`iPreview_${e.id}`} onClick={() => moveDetailPf(e.id)}>
          <img src={e.thumbnail} width={700} height={500} />
          <p className={"title"}>{e.pfPreviewTitle}</p>
          <p className={"host"}>{e.pfTitle}</p>
        </SliderItem>
      ))}
    </Slider>
  );
};

const SliderItem = styled.div`
  margin-right: 50px;
  /* padding-right: 50px; */
  display: flex;
  color: white;

  ${(props) =>
    props.$isMobile &&
    css`
      margin-right: 0;
      flex-direction: column;
      border-bottom: 1px solid white;
      margin-bottom: 20px;
    `}

  > img {
    display: block;
    /* width: 100%; */
    /* max-width: 500px; */
  }
  > p {
    margin: 0;
    margin-right: 50px;
  }
  > .title {
    font-size: 25px;
    font-weight: bold;
    padding-top: 35px;
    margin-bottom: 0;
    padding-bottom: 15px;
  }
  > .host {
    font-size: 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid white;

    ${(props) =>
      props.$isMobile &&
      css`
        border-bottom: none;
      `}
  }
`;

export default PfPreviewMain;
