import { Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import PFDetailBody from "../components/portf/PFDetailBody";
import PFDetailFooter from "../components/portf/PFDetailFooter";
import fetchDetailData from "../service/history/fetchDetailData";
import { isMobile } from "react-device-detect";

const PortfDetail = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrap $isMobile={isMobile}>
      <Suspense fallback={<div style={{ height: "100vh" }}></div>}>
        <PFDetailBody resource={fetchDetailData(id)} />
      </Suspense>
    </Wrap>
  );
};

const Wrap = styled.div`
  background-color: black;
  padding: 368px 0;
  ${(props) =>
    props.$isMobile &&
    css`
      padding: 5em 0;
    `}
`;

export default PortfDetail;
