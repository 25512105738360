import styled from "styled-components";
import { media } from "../../utils/media";

const TechImgs = ({ label, imgs }) => {
  return (
    <TechImgWrap>
      <div className={"tech-rounded-border"}>
        <span>{label}</span>
      </div>
      <div className={"tech-flex-image"}>
        <div className={"tf-main"}>
          <img src={imgs[0]} />
        </div>
        <div className={"tf-etc"}>
          {imgs != null &&
            imgs.map(
              (e, index) => index !== 0 && <img src={e} key={label + index} />
            )}
        </div>
      </div>
    </TechImgWrap>
  );
};

const TechImgWrap = styled.div`
  margin-top: 91px;
  ${media.large`
  margin-top: 80px;
      `}
  .tech-rounded-border {
    width: fit-content;
    border: 1px solid #ffffff;
    border-radius: 24px;
    margin-bottom: 32px;

    > span {
      display: inline-block;
      padding: 15px 45px;
      color: white;
      font-size: 15px;

      ${media.large`
        padding: 8px 30px;
        font-size: 12px;
      `}
    }
  }

  .tech-flex-image {
    display: flex;
    justify-content: start;
    align-items: stretch;
    gap: 0 10px;
    /* max-width: 358px; */
    ${media.large`
    gap: 0 8px;
      `}

    .tf-main {
      display: inline-block;
      /* flex-grow: 1; */
      max-width: 358px;
      height: 210px;

      ${media.large`
      max-width: 213px;
      height: 120px;
      `}
      > img {
        height: 100%;

        ${media.large`
        height: 100%;
      `}
      }
    }
    .tf-etc {
      max-width: 358px;
      height: 210px;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      ${media.large`
      max-width: 213px;
      height: 120px;
      gap: 8px;
      `}

      > img {
        width: calc((100% / 2) - 20px);
      }
    }
  }
`;

export default TechImgs;
