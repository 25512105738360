import { useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { pfCategory } from "../../service/recoil/history/category.atom";

const PFTopMobile = ({ resource }) => {
  const [curIndex, setCurIndex] = useState(0);
  const [cgRecoil, setCgRecoil] = useRecoilState(pfCategory);
  const changeIndex = (index, label) =>
    setCgRecoil({ value: index, label: label });

  const codeList = resource.codeList.read();

  return (
    <PFWrap>
      <section className={"pf-header"}>
        <p className={"pf-main-title"}>ALL WORKS</p>
        <p className={"pf-main-title blue"}>IN HERE</p>
      </section>
      <section className={"pf-menu"}>
        <ul>
          <li>
            <span
              className={`${cgRecoil.value === 0 && "active"}`}
              onClick={() => changeIndex(0, "ALL")}
            >
              ALL
            </span>
          </li>
          {codeList
            .sort((a, b) => a.pfOrd - b.pfOrd)
            .map((e, i) => (
              <li key={`codeList_${e.id}`}>
                <span
                  className={`${cgRecoil.value === i + 1 && "active"}`}
                  onClick={() => changeIndex(i + 1, e.pfCode)}
                >
                  {e.pfCodeKr}
                </span>
              </li>
            ))}

          {/* <li>
            <span
              className={`${curIndex === 2 && "active"}`}
              onClick={() => changeIndex(2)}
            >
              체육대회 행사
            </span>
          </li>
          <li>
            <span
              className={`${curIndex === 3 && "active"}`}
              onClick={() => changeIndex(3)}
            >
              연예인 섭외
            </span>
          </li>
          <li>
            <span
              className={`${curIndex === 4 && "active"}`}
              onClick={() => changeIndex(4)}
            >
              지역 행사
            </span>
          </li>
          <li>
            <span
              className={`${curIndex === 5 && "active"}`}
              onClick={() => changeIndex(5)}
            >
              공식 행사
            </span>
          </li>
          <li>
            <span
              className={`${curIndex === 6 && "active"}`}
              onClick={() => changeIndex(6)}
            >
              시스템 협업
            </span>
          </li> */}
        </ul>
      </section>
    </PFWrap>
  );
};

const PFWrap = styled.div`
  background-color: black;
  padding-top: 150px;
  padding-left: 25px;
  padding-right: 25px;

  > section {
    &.pf-header {
      > p {
        color: white;
        &.pf-main-title {
          font-family: "SB font";
          font-size: 50px;
          font-weight: bold;
          &.blue {
            color: #525ceb;
          }
        }
      }
    }

    &.pf-menu {
      padding-top: 50px;
      display: block;
      overflow-x: hidden;

      > ul {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: nowrap;
        overflow-x: scroll;
        white-space: nowrap;
        padding-bottom: 10px;

        > li {
          padding-right: 20px;
          > span {
            font-family: "SB font";
            color: #949494;

            &.active {
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;

export default PFTopMobile;
