import React, { Suspense, useEffect } from "react";
import styled, { css } from "styled-components";
import section1Bg from "../assets/images/company/bg_c_01.jpg";
import AboutTop from "../components/about/AboutTop";
import AboutUs from "../components/about/AboutUs";
import AboutHistory from "../components/about/AboutHistory";

import { HistoryList } from "../components/model/aboutHistoryItem";
import HelmetMeta from "../utils/helmet";
import { isMobile } from "react-device-detect";
import fetchCData from "../service/company/fetchData";

const About = () => {
  const list = HistoryList;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HelmetMeta
        description={
          "불가능을 가능으로, 변화에 발빠르게 적응하고 끊임없는 의문을 던지다."
        }
        title={"TOGETHER TOMORROW 팝콘 엔터테인먼트"}
        // imagesrc={}
        // url={"http://"}
      />
      <AboutWrap>
        <AboutTop />
        <Suspense fallback={<div></div>}>
          <AboutUs resource={fetchCData()} />
        </Suspense>

        <Suspense fallback={<div></div>}>
          <AboutHistory resource={fetchCData()} />
        </Suspense>
      </AboutWrap>
    </>
  );
};

const AboutWrap = styled.div``;

export default About;
