import { Helmet } from "react-helmet-async";

const HelmetMeta = (props) => {
  return (
    <Helmet>
      <title>POPCORN ENT.</title>
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={"%PUBLIC_URL%/ogImage.jpg"} />
      <meta property="og:url" content={props.url} />
    </Helmet>
  );
};

export default HelmetMeta;
