import styled from "styled-components";
import bgImg from "../../assets/images/company/bg_c_01.jpg";
import { useEffect, useState } from "react";
import { ImgLoading } from "../elements/loading";
import { useImgLoadingStatus } from "../hook/LoadingStatus";
import { media } from "../../utils/media";

const AboutTop = () => {
  const isImgLoading = useImgLoadingStatus(bgImg);

  if (isImgLoading) {
    return <ImgLoading />;
  }

  return (
    <Top $bg={bgImg}>
      <div className={"grid grid-cols-2 text-white aboutWrap"}>
        <div className={"flex justify-end"}>
          <div className={"aboutTopTitle"}>
            <LargeText className={"font-bold block"}>
              YOUR BEST
              <br />
              EVENT PLANNER
            </LargeText>
            <LargeText className={"font-bold block text-mainblue"}>
              POPCORN ENT.
            </LargeText>
          </div>
        </div>
        <div className={"flex justify-start"}>{/* <span>text</span> */}</div>
        <div className={"flex justify-start"}>{/* <span>text</span> */}</div>
        <TopSummary className={"flex justify-start"}>
          <span className={"font-bold"}>
            PCN Ent. 는 풍부한 경험을 통해 쌓아온 각 분야의 검증된 전문가 들과
            함께합니다. 언제나 불가능을 가능으로, 이상적인 이벤트를 구현하고
            실현해 왔습니다. 변화를 두려워하지 않는 구성원들의 강한 의지로
            니즈에 맞춘 독창적인 컨설팅을 제안하고, 창의적인 결과물을
            도출합니다. 더 나아가 기대 그 이상의 가치 실현을 제공하기 위해 늘
            심사숙고합니다. 클라이언트의 가치가 더 큰 기쁨으로 다가갈 수 있도록,
            문화의 중심에서 발빠르게 앞장서겠습니다.
          </span>
        </TopSummary>
      </div>
    </Top>
  );
};

const Top = styled.div`
  background-image: url(${(props) => props.$bg});
  background-size: cover;
  padding-top: 255px;
  padding-bottom: 170px;
  > div.aboutWrap {
    padding: 0 260px;

    ${media.large`
    padding: 0 130px;
    `}

    ${media.mLarge`
    padding:0 50px;
    `}
  }
`;
const LargeText = styled.span`
  font-family: "SB font";
  font-size: 70px;

  ${media.large`
  font-size:60px;
  `}

  ${media.medium`
  font-size:40px;
  `}

  ${media.mLarge`
  font-size:20px;
  `}
`;
const TopSummary = styled.div`
  max-width: 700px;
  line-height: 2;

  ${media.mLarge`
  max-width:100%;
  `}

  > span {
    font-size: 25px;

    ${media.large`
    font-size:20px;
    `}
    ${media.medium`
  font-size:15px;
  `}

  ${media.mLarge`
  font-size:12px;
  `}
  }
`;

export default AboutTop;
