import { css } from "styled-components";

const bpList = {
  large: 1440,
  medium: 1024,
  zfold: 691,
  small: 768,
  mLarge: 430,
};
const media = Object.entries(bpList).reduce((acc, [key, value]) => {
  return {
    ...acc,
    [key]: (first, ...interpolations) => css`
      @media (max-width: ${value}px) {
        ${css(first, ...interpolations)}
      }
    `,
  };
}, {});

export { media };
