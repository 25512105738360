function fetchPreviewList(code) {
  let previewList = [];

  const suspender = fetch(
    `${process.env.REACT_APP_API_PREFIX}/pf/preview?code=${code}`
  )
    .then((response) => response.json())
    .then((data) => (previewList = data));

  return {
    read() {
      if (previewList.length === 0) {
        throw suspender;
      } else {
        return previewList;
      }
    },
  };
}

function fetchPVData(code) {
  return {
    previewList: fetchPreviewList(code),
  };
}

export default fetchPVData;
