import { FadeLoader } from "react-spinners";
import styled from "styled-components";

const override = {
  display: "block",
};
export const ImgLoading = ({ color }) => {
  return (
    <ImgLoadingWrap $color={color}>
      <FadeLoader color={"#ffffff"} />
    </ImgLoadingWrap>
  );
};

const ImgLoadingWrap = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => (props.$color ? props.$color : "black")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
