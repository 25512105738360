import { HiPlus } from "react-icons/hi";
import styled from "styled-components";

import Img_Alerm from "../../assets/images/business/school_img.png";
import Img_Flag from "../../assets/images/business/flag_img.png";
import Img_People from "../../assets/images/business/people_img.png";
import Img_spanDot from "../../assets/images/business/contest_img.png";
import img_calander from "../../assets/images/business/calander_img.png";
import Img_system from "../../assets/images/business/system_img.png";
import { media } from "../../utils/media";
import { useNavigate } from "react-router-dom";

const bizMiddleData = [
  {
    no: 1,
    imgSrc: Img_Alerm,
    roundedText: "School Event",
    label: "학교 행사",
    summary:
      "초,중,고를 지나 대학교를 거치는 과정에서 자신의 자아와 열정을 찾으려는 학생들의 교감과 소통의 장을 마련해 주고 특별한 마음의 기반을 심어주는 중요한 요소를 반영합니다.",
    footer:
      "대학교축제, OT, MT / 개교기념행사 / 동아리축제 / 과행사 / 골든벨 / 수련회",
  },
  {
    no: 2,
    imgSrc: Img_Flag,
    roundedText: "Field Day",
    label: "체육대회 행사",
    summary:
      "한 해의 단합을 위하여 모든 분들의 열정을 불태우는특별한 시간, 몸과 마음이 힐링되고 충전되는 화합의 장을 완성합니다.",
    footer: "마을체육대회 / 명랑운동회 / 단합행사 / 야유회",
  },
  {
    no: 3,
    imgSrc: Img_People,
    roundedText: "Entertainment",
    label: "연예인 섭외",
    summary:
      "각종 행사 및 이벤트에 필요한 연예인 섭외와 더불어 효과적인 컨설팅으로 행사 전반을 담당하고 진행합니다.",
    footer:
      "결혼식 사회, 축가가수 / 돌잔치 / 기념식 / 워크샵 / 레크레이션 / 강연, 강의",
  },
  {
    no: 4,
    imgSrc: Img_spanDot,
    roundedText: "Local Event",
    label: "지역 행사",
    summary:
      "각 지역의 특상품을 판매하고 홍보해, 지역 주민과 관광객 사이 교류의 장을 제공합니다. 더 나은 지역사회 발전을 위한 이색적인 공간과 분위기를 완성합니다.",
    footer:
      "소상공인의 날 / 지역문화축제 / 특산물축제 / 테마축제 / 지역노래자랑대회",
  },
  {
    no: 5,
    imgSrc: img_calander,
    roundedText: "Official Event",
    label: "공식 행사",
    summary:
      "각 공간의 새로운 명분이나 기업의 중요한 분들을 존중해주는 특별한 기념의 날을 조성, 상황과 규모에 맞는 세련되고 품격있는 공간의 장을 완성합니다.",
    footer:
      "기업시상식 / 준공식 / 기공식 / 위촉식 / 현판식 / 개소식 / 이취임식",
  },
  {
    no: 6,
    imgSrc: Img_system,
    roundedText: "Provide system",
    label: "시스템 협업",
    summary:
      "국내 검증된 최고의 하드시스템 팀과 협력하여 완벽한 행사를 구성합니다.",
    footer: "무대 / 음향 / 조명 / 중계 / LED / 특효 / 행사용품",
  },
];

const BizMiddle = () => {
  const navi = useNavigate();
  const movePortf = () => {
    navi("/portf");
  };
  return (
    <Wrap>
      <p className={"text-center text-mainblue"}>WHAT WE DO</p>
      <p className={"text-center text-mainblue divider"}>|</p>
      <div className={"bizMiddle-contents"}>
        <div className={"biz-grid text-white"}>
          {bizMiddleData.map((item) => (
            <article className={"biz-item"} key={item.no}>
              {/* flex */}
              <section className={"item-contents"}>
                <div className={"label-top"}>
                  <div className={"contents-label"}>
                    <div className={"label-img"}>
                      <img src={item.imgSrc} width={72} />
                    </div>
                    <div className={"label-text"}>
                      <div className={"label-text-border-span"}>
                        <span>{item.roundedText}</span>
                      </div>
                      <div className={"label-text-span"}>
                        <span>{item.label}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <summary className={"label-summary"}>
                  <p>{item.summary}</p>
                </summary>
              </section>
              <div className={"item-footer"}>
                <p>{item.footer}</p>
              </div>
            </article>
          ))}
        </div>
      </div>
      <div className={"more-area"}>
        <button type={"button"} onClick={movePortf}>
          <span>View more</span>
          <HiPlus color="#ffffff" />
        </button>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  background-color: #000000;
  padding: 110px 0;

  summary {
    display: unset;
  }
  .biz-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;

    ${media.mLarge`
    grid-template-columns: repeat(1, minmax(0, 1fr));
    `}
  }
  .more-area {
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.mLarge`
    padding-top:40px;
    
    `}
    > button {
      display: flex;
      padding: 15px 34px;
      justify-content: center;
      align-items: center;
      border: 1px solid #ffffff;
      border-radius: 24px;
      gap: 0 11px;
      > span {
        color: white;
        font-size: 15px;
      }
    }
  }
  > p {
    font-family: "SB font";
    letter-spacing: -0.75px;
    font-weight: bold;
    font-size: 30px;
    padding-bottom: 30px;

    &.divider {
      font-weight: 300;
      font-family: "NanumSquare Neo";
      padding-bottom: 0;
    }
  }

  > div {
    &.bizMiddle-contents {
      padding-top: 54px;
      max-width: 1400px;
      margin: 0 auto;

      .biz-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        padding: 32px;
        min-height: 298px;
        border: 1px solid #ffffff;

        .item-contents {
          display: flex;
          justify-content: space-between;
          align-items: start;
          flex-direction: column;
          width: 100%;
          .label-top {
            display: flex;
            justify-content: space-between;
            align-items: start;
            width: 100%;
            padding-bottom: 13px;
          }
          .label-summary {
            border-top: 1px solid #ffffff;
            padding-top: 13px;
            > p {
              font-size: 15px;
              letter-spacing: -0.38px;
            }
          }

          .contents-label {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 0 12px;

            .label-img {
            }

            .label-text {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: start;
              gap: 9px 0;
              .label-text-border-span {
                border: 1px solid #525ceb;
                border-radius: 24px;

                > span {
                  padding: 6px 12px;
                  font-size: 10px;
                  font-family: "SB font";
                  font-weight: bold;
                  letter-spacing: -0.25px;
                  line-height: 1.2;
                  color: #525ceb;
                }
              }
              .label-text-span {
                span {
                  font-family: "SB font";
                  font-size: 20px;
                  font-weight: bold;
                }
              }
            }
          }
        }
        .item-footer {
          > p {
            color: #949494;
            font-size: 12px;
            letter-spacing: -0.3px;
            word-break: keep-all;
          }
        }
      }
    }
  }
`;

export default BizMiddle;
