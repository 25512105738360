export const TermsData = {
  use: {
    key: "use",
    title: "이용약관",
    list: [
      {
        order: 1,
        text: "'콘텐츠'란 정보통신망법의 규정에 따라 정보통신망에서 사용되는 부호·문자·음성·음향·이미지 또는 영상등으로 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 말합니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 2,
        text: "가. 회사는 최종 사용일로부터 연속하여 1년 동안 서비스 사용 이력이 없는 경우 '개인정보보호법' 및 같은 법 시행령의 규정에 따라 이용자 정보를 다른 이용자의 개인정보와 분리하여 별도로 저장 및 관리 할 수 있습니다. 이때, 분리 저장된 이용자의 개인정보는 이용자가 회원탈퇴신청 또는 개인정보삭제 요청을 할때까지 보관됩니다.",
        isBold: false,
        isTitle: false,
        isSubItem: true,
      },
      {
        order: 3,
        text: "나. 회사가 이용자의 개인정보의 보호 및 사용에 대해서 관련 법규 및 회사의 개인정보처리방침을 적용합니다. 다만, 회사에서 운영하는 웹 사이트 등에서 링크된 외부 웹페이지에서는 회사의 개인정보 처리방침이 적용되지 않습니다.",
        isBold: false,
        isTitle: false,
        isSubItem: true,
      },
      {
        order: 4,
        text: "다. 회사가 제공하는 구체적인 서비스의 내용은 본 약관에 별도로 첨부된 '별표. 서비스의 내용'과 같습니다.",
        isBold: false,
        isTitle: false,
        isSubItem: true,
      },
      {
        order: 5,
        text: "제 1조 (정보의 제공 및 광고의 게재)",
        isBold: true,
        isTitle: true,
        isSubItem: false,
      },
      {
        order: 6,
        text: "1. 회사는 이용자가 서비스 이용 중 필요하다고 인정되는 각종 정보 및 광고를 배너 게제, 전자우편(E-Mail), 휴대전화 메시지, 전화, 우편 등의 방법으로 이용자에게 제공(또는 전송)할 수 있습니다. 다만, 이용자는 이를 원하지 않을 경우 회사가 제공하는 방법에 따라 수신을 거부할 수 있습니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 7,
        text: "2. 이용자가 수신 거부를 한 경우에도 이용약관, 개인정보보호정책, 기타 이용자의 이익에 영향을 미칠 수 있는 중요한 사항의 변경 등 '정보통신망이용촉진 및 정보보호 등에 관한 법률'에서 정하는 사유 등 이용자가 반드시 알고 있어야 하는 사항에 대하여는 전자우편 등의 방법으로 정보를 제공할 수 있습니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 8,
        text: "3. 제 1항 단서에 따라 이용자가 수신 거부 조치를 취한 경우 이로 인하여 회사가 거래 관련 정보, 이용 문의에 대한 답변 등의 정보를 전달하지 못한 경우 회사는 이로 인한 책임이 없습니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 9,
        text: "4. 회사는 '정보통신망법' 시행령에 따라 2년마다 영리 목적의 광고성 정보 전송에 대한 수신동의 여부를 확인합니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 10,
        text: "5. 회사는 광고주의 판촉 활동에 이용자가 참여하거나, 거래의 결과로서 발생하는 손실 또는 손해에 대하여는 책임을 지지 않습니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 11,
        text: "6. 제 1항의 규정에도 불구하고 이용자가 직접 작성한 콘텐츠 및 회사의 제휴계약에 따라 제공된 저작물에 대한 지식재산권은 회사에 귀속되지 않습니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 12,
        text: "제 2조 (콘텐츠의 관리)",
        isBold: true,
        isTitle: true,
        isSubItem: false,
      },
      {
        order: 13,
        text: "1. 회원이 작성 또는 창작한 콘텐츠가 '개인정보보호법' 및 '저작권법' 등 관련 법에 위반되는 내용을 포함하는 경우, 관리자는 관련 법이 정한 절차에 따라 해당 콘텐츠의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련 법에 따라 조치를 취하여야 합니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 14,
        text: "2. 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련 법에 위반되는 경우에는 관련 법에 따라 해당 콘텐츠에 대해 임시조치 등을 취할 수 있습니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 15,
        text: "제 3조 (콘텐츠의 저작권)",
        isBold: true,
        isTitle: true,
        isSubItem: false,
      },
      {
        order: 16,
        text: "1. 이용자가 서비스 내에 게시한 콘텐츠의 저작권은 해당 콘텐츠의 저작자에게 귀속됩니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 17,
        text: "2. 제 1항에 불구하고 회사는 서비스의 운영, 전시, 전송, 배포, 홍보 등의 목적으로 별도의 허락없이 무상으로 저작권법 및 공정한 거래관행에 합치되는 범위 내에서 다음 각 호와 같이 회원이 등록한 콘텐츠를 사용할 수 있습니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 18,
        text: "가. 서비스 내에서 이용자가 작성한 콘텐츠의 복제, 수정, 전시, 전송, 배포 등 저작권을 침해하지 않는 범위 내의 2차적 저작물 또는 편집 저작물 작성을 위한 사용. 다만, 해당 콘텐츠를 등록한 이용자가 해당 콘텐츠의 삭제 또는 사용 중지를 요청하는 경우 회사는 관련 법에 따라 보존해야하는 사항을 제외하고 관련 콘텐츠를 모두 삭제 또는 사용중지합니다.",
        isBold: false,
        isTitle: false,
        isSubItem: true,
      },
      {
        order: 19,
        text: "나. 서비스의 운영, 홍보, 서비스 개선 및 새로운 서비스 개발을 위한 범위 내의 사용",
        isBold: false,
        isTitle: false,
        isSubItem: true,
      },
      {
        order: 20,
        text: "다. 미디어, 통신사 등을 통한 홍보목적으로 이용자의 콘텐츠를 제공, 전시하도록 하는 등의 사용",
        isBold: false,
        isTitle: false,
        isSubItem: true,
      },
      {
        order: 21,
        text: "제 4조(관할법원 및 준거법)",
        isBold: true,
        isTitle: true,
        isSubItem: false,
      },
      {
        order: 22,
        text: "서비스와 관련하여 분쟁이 발생한 경우 관할법원은 민사소송법에 따른 관할법으로 정하며, 준거법은 대한민국의 법령을 적용합니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 23,
        text: "부    칙",
        isBold: true,
        isTitle: true,
        isSubItem: false,
      },
      {
        order: 24,
        text: "제 1조(시행일)",
        isBold: true,
        isTitle: true,
        isSubItem: false,
      },
      {
        order: 25,
        text: "본 약관은 2024.06.12. 부터 시행됩니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
    ],
  },
  email: {
    key: "email",
    title: "이메일 무단 수집 거부",
    list: [
      {
        order: 1,
        text: "본 웹 사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그밖의 기술적 장치를 이용하여 '무단으로 수집되는 것을 거부'하며,이를 위반시 정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 2,
        text: "누구든지 전자우편주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 3,
        text: "누구든지 제 1항의 규정을 위반하여 수집된 전자우편주소를 판매·유통하여서는 아니된다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 4,
        text: "누구든지 제 1항 및 제 2항의 규정에 의하여 수집·판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보전송에 이용하여서는 아니된다.",
        isBold: false,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 5,
        text: "담당자 : 민정현",
        isBold: true,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 6,
        text: "전화번호 : 010-4676-5556",
        isBold: true,
        isTitle: false,
        isSubItem: false,
      },
      {
        order: 7,
        text: "게시일 : 2024년 06월 12일",
        isBold: true,
        isTitle: false,
        isSubItem: false,
      },
    ],
  },
};
