import styled from "styled-components";
import leftImg from "../../assets/images/business/img_01-1.jpg";
import leftCutImg from "../../assets/images/business/img_01.jpg";
import centerImg from "../../assets/images/business/img_02-1.jpg";
import centerCutImg from "../../assets/images/business/img_02.jpg";
import rightImg from "../../assets/images/business/img_03-1.jpg";
import rightCutImg from "../../assets/images/business/img_03.jpg";

import MultiA1 from "../../assets/images/business/multi_a_1.jpg";
import MultiA2 from "../../assets/images/business/multi_a_2.jpg";
import MultiA3 from "../../assets/images/business/multi_a_3.jpg";
import MultiA4 from "../../assets/images/business/multi_a_4.jpg";
import MultiA5 from "../../assets/images/business/multi_a_5.jpg";

import WallA1 from "../../assets/images/business/wall_a_1.jpg";
import WallA2 from "../../assets/images/business/wall_a_2.jpg";
import WallA3 from "../../assets/images/business/wall_a_3.jpg";
import WallA4 from "../../assets/images/business/wall_a_4.jpg";
import WallA5 from "../../assets/images/business/wall_a_5.jpg";

import SoundB1 from "../../assets/images/business/sound_b_1.jpg";
import SoundB2 from "../../assets/images/business/sound_b_2.jpg";
import SoundB3 from "../../assets/images/business/sound_b_3.jpg";
import SoundB4 from "../../assets/images/business/sound_b_4.jpg";
import SoundB5 from "../../assets/images/business/sound_b_5.jpg";

import ProjectB1 from "../../assets/images/business/project_b_1.jpg";
import ProjectB2 from "../../assets/images/business/project_b_2.jpg";
import ProjectB3 from "../../assets/images/business/project_b_3.jpg";
import ProjectB4 from "../../assets/images/business/project_b_4.jpg";
import ProjectB5 from "../../assets/images/business/project_b_5.jpg";

import PreventC1 from "../../assets/images/business/prevent_c_1.jpg";
import PreventC2 from "../../assets/images/business/prevent_c_2.jpg";
import PreventC3 from "../../assets/images/business/prevent_c_3.jpg";
import PreventC4 from "../../assets/images/business/prevent_c_4.jpg";
import PreventC5 from "../../assets/images/business/prevent_c_5.jpg";

import QuarantineC1 from "../../assets/images/business/quarantine_c_1.jpg";
import QuarantineC2 from "../../assets/images/business/quarantine_c_2.jpg";
import QuarantineC3 from "../../assets/images/business/quarantine_c_3.jpg";
import QuarantineC4 from "../../assets/images/business/quarantine_c_4.jpg";
import QuarantineC5 from "../../assets/images/business/quarantine_c_1.jpg";

import { ReactComponent as LeftIcon } from "../../assets/icons/arrow_right_03.svg";
import { useState } from "react";
import TechImgs from "../elements/techImg";
import { media } from "../../utils/media";

const BizTech = () => {
  const [tab, setTab] = useState(0);

  const changeTab = (index) => setTab(index);
  const resetTab = () => setTab(0);
  return (
    <Wrap>
      <section className={"tech-title"}>
        <p>PCN'S SYSTEM TECH</p>
      </section>
      <section className={`tech-triple-background ${tab === 1 && "activeBg"}`}>
        <div className={"tech-triple-bg-wrap"}>
          <img src={leftImg} />
          <div className={"tech-triple-bg-contents"}>
            <div className={"tech-bg-wrap"}>
              <div className={"tech-bg-summary"}>
                <div>
                  <p className={"tech-item-title"}>멀티 시스템</p>
                  <span className={"tech-item-summary bg-item"}>
                    행사를 더 품격있게 서포트해주는 중요한 멀티 시스템을
                    제안합니다.
                  </span>
                  <div className={"tech-item-more"}>
                    <LeftIcon
                      width={45}
                      height={45}
                      onClick={resetTab}
                    ></LeftIcon>
                  </div>
                </div>
              </div>
              <div className={"tech-bg-image-area"}>
                {/* img area */}
                <TechImgs
                  label={"행사용품 렌탈"}
                  imgs={[MultiA5, MultiA1, MultiA2, MultiA3, MultiA4]}
                />
                <TechImgs
                  label={"포토월 아이디어"}
                  imgs={[WallA1, WallA2, WallA3, WallA4, WallA5]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`tech-triple-background ${tab === 2 && "activeBg"}`}>
        <div className={"tech-triple-bg-wrap"}>
          <img src={centerImg} />
          <div className={"tech-triple-bg-contents"}>
            <div className={"tech-bg-wrap"}>
              <div className={"tech-bg-summary"}>
                <div style={{ paddingBottom: "4em" }}>
                  <p className={"tech-item-title"}>
                    SOUND는 예술이자 동시에
                    <br />
                    과학입니다
                  </p>
                  <span className={"tech-item-summary bg-item sub"}>
                    이 두가지 요소를 하나로 만드는 작업을 하고 있는
                    <br />
                    popcorn 엔터테인먼트는 최고의 장비와 시스템, 탄탄한
                    전문지식,
                    <br />
                    오랜 경험과 노하우를 바탕으로 한<br />
                    전문 엔지니어 크루입니다.
                  </span>
                  <span className={"tech-item-summary bg-item sub"}>
                    지난 20여 년간 popcorn 엔터테인먼트는
                    <br />
                    콘서트, 뮤지컬, 페스티벌, 국가/기업 행사 등
                    <br />
                    다양한 분야에서 클라이언트의 기대를 뛰어넘는
                    <br />
                    최고의 기술과 서비스를 제공함과 동시에
                    <br />
                    관객을 감동시키는 사운드를 만들어 왔습니다.
                  </span>
                  <span className={"tech-item-summary bg-item sub"}>
                    최고의 라이브 사운드를 위해 끊임없이
                    <br />
                    노력하는 popcorn 엔터테인먼트가 되겠습니다.
                  </span>
                </div>
                <div>
                  <p className={"tech-item-title"}>음향 / 조명 / 영상</p>
                  <span className={"tech-item-summary bg-item"}>
                    행사의 성격에 맞는 하이 퀄리티의 음향, 조명, 영상 시스템을
                    제공합니다.
                  </span>
                  <div className={"tech-item-more"}>
                    <LeftIcon
                      width={45}
                      height={45}
                      onClick={resetTab}
                    ></LeftIcon>
                  </div>
                </div>
              </div>
              <div className={"tech-bg-image-area"}>
                {/* img area */}
                <TechImgs
                  label={"음향&조명 시스템"}
                  imgs={[SoundB1, SoundB2, SoundB3, SoundB4, SoundB5]}
                />
                <TechImgs
                  label={"영상 시스템"}
                  imgs={[ProjectB1, ProjectB2, ProjectB3, ProjectB4, ProjectB5]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`tech-triple-background ${tab === 3 && "activeBg"}`}>
        <div className={"tech-triple-bg-wrap"}>
          <img src={rightImg} />
          <div className={"tech-triple-bg-contents"}>
            <div className={"tech-bg-wrap"}>
              <div className={"tech-bg-summary"}>
                <div>
                  <p className={"tech-item-title"}>방역 시스템</p>
                  <span className={"tech-item-summary bg-item"}>
                    코로나 19 시기에 맞춘 철저한 방역 및 안전 시스템을
                    제공합니다.
                  </span>
                  <div className={"tech-item-more"}>
                    <LeftIcon
                      width={45}
                      height={45}
                      onClick={resetTab}
                    ></LeftIcon>
                  </div>
                </div>
              </div>
              <div className={"tech-bg-image-area"}>
                {/* img area */}
                <TechImgs
                  label={"축제 방역"}
                  imgs={[PreventC1, PreventC2, PreventC3, PreventC4, PreventC5]}
                />
                <TechImgs
                  label={"공기살균기"}
                  imgs={[
                    QuarantineC1,
                    QuarantineC2,
                    QuarantineC3,
                    QuarantineC4,
                    QuarantineC5,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={"tech-triple-images"}>
        <div
          className={`tech-triple-images-item tech-left ${
            tab === 1 && "active"
          }`}
        >
          <img src={leftCutImg} />
          <div className={`tech-item right `}>
            <p className={"tech-item-title"}>멀티 시스템</p>
            <span className={"tech-item-summary"}>
              행사를 더 품격있게 서포트해주는 중요한 멀티 시스템을 제안합니다.
            </span>
            <div className={"tech-item-button"}>
              <LeftIcon
                width={45}
                height={45}
                onClick={() => changeTab(1)}
              ></LeftIcon>
            </div>
          </div>
        </div>
        <div
          className={`tech-triple-images-item tech-center ${
            tab === 2 && "active"
          }`}
        >
          <img src={centerCutImg} />
          <div className={"tech-item center"}>
            <p className={"tech-item-title"}>음향 / 조명 / 영상</p>
            <span className={"tech-item-summary"}>
              행사의 성격에 맞는 하이 퀄리티의 음향, 조명, 영상 시스템을
              제공합니다.
            </span>
            <div className={"tech-item-button"}>
              <LeftIcon
                width={45}
                height={45}
                onClick={() => changeTab(2)}
              ></LeftIcon>
            </div>
          </div>
        </div>
        <div
          className={`tech-triple-images-item tech-right ${
            tab === 3 && "active"
          }`}
        >
          <img src={rightCutImg} />
          <div className={"tech-item left"}>
            <p className={"tech-item-title"}>방역 시스템</p>
            <span className={"tech-item-summary"}>
              코로나 19 시기에 맞춘 철저한 방역 및 안전 시스템을 제공합니다.
            </span>
            <div className={"tech-item-button"}>
              <LeftIcon
                width={45}
                height={45}
                onClick={() => changeTab(3)}
              ></LeftIcon>
            </div>
          </div>
        </div>
      </section>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
  background-color: #000000;

  > section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    .tech-item-title {
      color: white;
      font-family: "SB font";
      font-weight: bold;
      font-size: 30px;
      padding-bottom: 25px;

      ${media.large`
            font-size:25px;
            `}
    }
    .tech-item-summary {
      color: white;
      display: block;
      font-size: 20px;

      &.bg-item {
        max-width: 323px;
      }
      &.sub {
        max-width: 420px;
        font-size: 18px;
        word-break: keep-all;
        padding-bottom: 1em;
        ${media.large`
            font-size:15px;
            `}
      }
      ${media.large`
            font-size:15px;
            `}
    }
    .tech-item-more,
    .tech-item-button {
      padding-top: 40px;
      > svg {
        cursor: pointer;
      }
    }

    &.tech-triple-background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 111;
      transform: translateX(-100%);
      transition: transform 0.5s ease;

      &.activeBg {
        transform: translateX(0);
      }

      .tech-triple-bg-wrap {
        position: relative;
        .tech-triple-bg-contents {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 111;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          .tech-bg-wrap {
            display: flex;
            justify-content: end;
            align-items: end;
            width: 100%;
            .tech-bg-summary {
              /* flex: 1; */

              flex: 1;
              > div {
                width: calc((100%) - 260px);
                margin-left: auto;
                /* max-width: 323px; */

                > div {
                  padding-top: 40px;
                  svg {
                    transform: rotateY(180deg);
                    cursor: pointer;
                  }
                }
              }
            }
            .tech-bg-image-area {
              flex: 1;
              /* flex: 1; */
              .tech-bg-label {
                padding: 15px 45px;
                border: 1px solid #ffffff;
                border-radius: 24px;
                color: white;
              }
            }
          }
        }
      }
    }

    &.tech-triple-images {
      position: relative;

      .tech-triple-images-item {
        position: relative;
        width: calc(100% / 3);

        &.active {
          transform: scale(90%);
          transition: transform 0.5s ease;
        }

        .tech-item {
          bottom: 12%;
          position: absolute;
          max-width: 323px;

          ${media.large`
          max-width:250px;
          `}

          &.right {
            right: 0;
            margin-right: 60px;
            text-align: left;
          }
          &.center {
            left: 0;
            right: 0;
            margin: 0 auto;
            /* transform: translate(50%); */
          }
          &.left {
            left: 0;
            margin-left: 60px;
            text-align: left;
          }
        }
      }
    }

    &.tech-title {
      position: absolute;
      top: 10%;
      left: 12%;

      > p {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -0.75px;
        font-family: "SB font";
        color: white;
      }
    }
  }
`;

export default BizTech;
