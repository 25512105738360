import styled from "styled-components";

import band1 from "../../assets/images/bg_03-1.jpg";
import mainBg3 from "../../assets/images/bg_03.jpg";
import title3 from "../../assets/images/title_03.png";
import { media } from "../../utils/media";

const HomeSection3 = () => {
  return (
    <SectionWrap>
      <div className={"bandArea"}>
        <img src={band1} />
      </div>
      <div className={"mainArea"}>
        <img src={mainBg3} />
        <div className={"mainContents"}>
          <p>팝콘 엔터테인먼트와 함께하는 획기적인 EVENT PLANNING</p>
          <img src={title3} />
          <div className={"floatText"}>
            <div className={"floatTextWrap"}>
              <p>Planning</p>
              <span>
                창의적인 생각으로부터
                <br />
                시작된 진취적인 기획력
              </span>
            </div>
            <div className={"floatTextWrap"}>
              <p>Progress</p>
              <span>
                체계적인 전문 프로세스로
                <br />
                발빠르게 실현하는 진행력
              </span>
            </div>
            <div className={"floatTextWrap"}>
              <p>Direction</p>
              <span>
                풍부한 경험을 바탕으로 다져진
                <br />
                독자적인 센스와 남다른 연출력
              </span>
            </div>
          </div>
          {/* <div className={"customRoundBoxWrap"}>
            <div className={"customRoundBox large "}></div>
            <div className={"customRoundBox middle"}></div>
            <div className={"customRoundBox"}></div>
          </div> */}
        </div>
      </div>
      <div className={"bandArea"}>
        <img src={band1} />
      </div>
    </SectionWrap>
  );
};

const SectionWrap = styled.div`
  position: relative;

  > .bandArea {
    > img {
      display: block;
      height: 115px;

      ${media.mLarge`
      height:50px;
      `}
    }
  }
  > .mainArea {
    position: relative;
    display: block;
    > img {
      display: block;
      width: 100%;

      ${media.mLarge`
        object-fit:cover;
        height: 100vh;
      `}
    }

    > .mainContents {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: block;
      text-align: center;
      padding-top: 109px;

      > p {
        color: #525ceb;
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 50px;
      }
      > img {
        display: unset;
        max-width: 941px;
        ${media.mLarge`
          max-width:100%;
          width:100%;
        `}
      }

      ${media.large`
      padding-top: 50px;
      >img{
        width:calc(100% - 260px);
      }

      ${media.mLarge`
      >p{
        word-break:keep-all;
        margin:0 50px;
      }
      >img{
        width:90%;
      }
      `}
      `}

      .floatText {
        display: flex;
        justify-content: center;
        align-items: start;
        margin-top: 250px;
        gap: 0 100px;

        .floatTextWrap {
          color: white;

          > p {
            font-family: "SB font";
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 30px;
          }
          > span {
            font-size: 15px;
          }
        }

        ${media.mLarge`
          margin-top:100px;
          padding:50px 0;
          flex-direction:column;
          align-items:center;
          background:transparent linear-gradient(100deg, #0F1A8E 0%, #0F1A8E 0%, #525CEB 49%, #9F6EEB 100%) 0% 0% no-repeat padding-box;

          .floatTextWrap{
            padding-bottom:50px;
            >p{
              margin-bottom:10px;
            }
            >span{

            }
          }
        `}
      }
      .customRoundBoxWrap {
        position: relative;
        max-width: calc(100% - 160px * 2);
        margin: 0 auto;

        .customRoundBox {
          display: none;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          border-radius: 244px;
          padding: 143px 238px;
          background: transparent
            linear-gradient(
              100deg,
              #0f1a8e 0%,
              #0f1a8e 0%,
              #525ceb 49%,
              #9f6eeb 100%
            )
            0% 0% no-repeat padding-box;
        }
      }
    }
  }
`;

export default HomeSection3;
