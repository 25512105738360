import styled, { css } from "styled-components";

import DetailImg from "../../assets/images/portfolio/img_10.jpg";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";

const PFDetailBody = ({ resource }) => {
  const [curIndex, setCurIndex] = useState(0);
  useEffect(() => {
    setCurIndex(0);
  }, []);
  const {
    id,
    pfTitle,
    pfCodePfCodeKr,
    pfDate,
    pfSummery,
    pfWork,
    pfDescription,
    thumbnail,
    subImages,
  } = resource.detail.read();

  const settings = {
    infinite: false,
    slidesToShow: 1,
    variableWidth: true,
    // variableHeight: true,
    arrows: false,
    slidesToScroll: 1,
    beforeChange: (prev, current) => setCurIndex(current),
  };

  return (
    <>
      <DetailWrap $isMobile={isMobile}>
        <div className={"pf-detail-thumbnail"}>
          <img src={thumbnail} />
        </div>
        <article>
          <section className={"pf-detail-title"}>
            <span className={"title-text"}>{pfTitle}</span>
            <span className={"rounded-border"}>{pfCodePfCodeKr}</span>
          </section>
          <section className={"pf-detail-summary"}>
            <summary>{pfSummery}</summary>
          </section>
          <section className={"pf-detail-etc"}>
            <ul>
              <li>
                <label>Date</label>
                <span>{pfDate}</span>
              </li>
              <li>
                <label>Work</label>
                <span>{pfWork}</span>
              </li>
              <li className={"block-line"}>
                <label>Description</label>
                <p>{pfDescription}</p>
              </li>
            </ul>
          </section>
        </article>
      </DetailWrap>
      <PFDetailWrap $isMobile={isMobile}>
        <p className={"footer-title"}>PHOTO VIEW</p>
        <div className={"list-scroll"}>
          <div className={"list-scroll-image"}>
            {isMobile ? (
              subImages
                .sort((e) => e.imgOrd)
                .map((e, i) => <img key={id + "-" + i} src={e.imageUrl} />)
            ) : (
              <Slider {...settings}>
                {subImages
                  .sort((e) => e.imgOrd)
                  .map((e, i) => (
                    <img key={id + "-" + i} src={e.imageUrl} />
                  ))}
              </Slider>
            )}
          </div>
          <div className={"list-scroll-indicator"}>
            <PFSlideIndicator $length={3} $curIndex={curIndex} />
          </div>
        </div>
      </PFDetailWrap>
    </>
  );
};

const DetailWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  ${(props) =>
    props.$isMobile &&
    css`
      flex-direction: column;
      gap: 1em 0;
    `}

  > .pf-detail-thumbnail {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  > article {
    margin-left: 80px;
    flex: 1;
    ${(props) =>
      props.$isMobile &&
      css`
        padding: 0 1em;
        margin: 0;
      `}

    > section {
      padding-bottom: 60px;
      max-width: calc(100% - 260px - 80px);
      margin-right: auto;
      ${(props) =>
        props.$isMobile &&
        css`
          max-width: 100%;
          margin: 0;
        `}
    }
    .pf-detail-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${(props) =>
        props.$isMobile &&
        css`
          display: block;
        `}
      span {
        color: white;
        &.title-text {
          flex: 2;
          font-size: 40px;
          font-weight: 800;

          ${(props) =>
            props.$isMobile &&
            css`
              display: block;
              margin-bottom: 0.5em;
              word-break: keep-all;
            `}
        }
        &.rounded-border {
          border: 1px solid #fff;
          border-radius: 24px;
          padding: 15px 34px;
          ${(props) =>
            props.$isMobile &&
            css`
              float: right;
            `}
        }
      }
    }
    .pf-detail-summary {
      ${(props) =>
        props.$isMobile &&
        css`
          padding: 2em 0;
          font-size: 1em;
        `}
      > summary {
        color: white;
        display: block;
        font-size: 25px;
        line-height: 2;
        font-weight: normal;
        ${(props) =>
          props.$isMobile &&
          css`
            font-size: 1em;
          `}
      }
    }
    .pf-detail-etc {
      > ul {
        > li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          padding-bottom: 25px;

          &.block-line {
            display: block;

            label {
              display: block;
              padding-bottom: 25px;
            }
            p {
              font-size: 15px;
              letter-spacing: -0.38px;
            }
          }

          label {
            font-size: 20px;
            font-weight: bold;
            line-height: 1;
          }

          span {
            font-size: 15px;
            line-height: 1;
          }
          p {
            display: block;
          }
        }
      }
    }
  }
`;
const PFDetailWrap = styled.div`
  padding-top: 100px;
  color: white;

  max-width: calc(100% - 260px);
  margin-left: auto;

  ${(props) =>
    props.$isMobile &&
    css`
      max-width: 100%;
      margin: 0;
    `}
  > p.footer-title {
    position: relative;
    display: inline-block;
    font-family: "SB font";
    font-size: 30px;
    letter-spacing: -0.75px;
    &::after {
      position: absolute;
      content: "|";
      right: -50px;
      font-family: "";
      font-weight: 300;
    }
  }

  > .list-scroll {
    padding: 50px 0;
    .list-scroll-image {
      padding-bottom: 50px;
      /* display: flex;
      flex-wrap: nowrap;
      justify-content: start;
      align-items: center; */
      /* overflow-x: scroll; */

      img {
        max-width: 576px;
        margin-right: 50px;

        ${(props) =>
          props.$isMobile &&
          css`
            max-width: 100%;
            margin: 0;
          `}
      }
    }

    .list-scroll-indicator {
      margin-top: 50px;
      position: relative;
      background-color: #949494;
      /* padding: 10px 0; */
      height: 3px;

      ${(props) =>
        props.$isMobile &&
        css`
          display: none;
        `}
    }
  }
`;

const PFSlideIndicator = styled.div`
  position: absolute;
  display: block;
  z-index: 1111;
  top: 0;
  bottom: 0;
  width: calc(100% / ${(props) => props.$length});
  height: 3px;
  color: white;
  background-color: white;
  transform: translateX(${(props) => props.$curIndex * 100 + "%"});
  transition: transform 0.5s ease;
`;
export default PFDetailBody;
