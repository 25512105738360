import styled from "styled-components";
import { media } from "../../utils/media";

export const RoundedButton = ({
  onClick,
  label,
  suffix,
  borderColor,
  textColor,
}) => {
  return (
    <RoundedButtonStyle
      type={"button"}
      onClick={onClick}
      $bColor={borderColor}
      $tColor={textColor}
    >
      {label}
      {suffix}
    </RoundedButtonStyle>
  );
};

const RoundedButtonStyle = styled.button`
  padding: 15px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
  border-radius: 24px;
  background-color: transparent;
  border: 1px solid ${(props) => props.$bColor || "white"};
  font-size: 15px;
  color: ${(props) => props.$tColor || "white"};
  cursor: pointer;

  ${media.mLarge`
  font-size:12px;
  padding:10px 20px;
  `}
`;
