import styled from "styled-components";
import Img from "../../assets/images/business/img_04.png";
import Imgs from "../../assets/images/business/img_05.png";
import bizB_1 from "../../assets/images/business/biz_b_1.jpg";
import bizB_2 from "../../assets/images/business/biz_b_2.jpg";
import bizB_3 from "../../assets/images/business/biz_b_3.jpg";
import bizB_4 from "../../assets/images/business/biz_b_4.jpg";
import bizB_5 from "../../assets/images/business/biz_b_5.jpg";
import bizB_6 from "../../assets/images/business/biz_b_6.jpg";
import bizB_7 from "../../assets/images/business/biz_b_7.jpg";
import bizB_8 from "../../assets/images/business/biz_b_8.jpg";
import bizB_9 from "../../assets/images/business/biz_b_9.jpg";
import bizB_10 from "../../assets/images/business/biz_b_10.jpg";
import { media } from "../../utils/media";

const BizBottom = () => {
  return (
    <Wrap>
      <img src={Img} />
      <div className={"bottom-images"}>
        <div className={"image-group sth"}>
          <img src={bizB_1} />
          <img src={bizB_9} />

          <img src={bizB_3} />
          <img src={bizB_8} />
        </div>
        <div className={"image-group"}>
          <img src={bizB_2} />
          <img src={bizB_4} />
          <img src={bizB_10} className={"double"} />
        </div>
      </div>
    </Wrap>
  );
};

const Wrap = styled.section`
  background-color: #000000;

  .bottom-images {
    padding: 130px 0;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px 0;

    .image-group {
      display: flex;
      /* width: 100%; */
      justify-content: start;
      align-items: start;
      flex-wrap: wrap;
      gap: 10px 10px;

      &.sth {
        align-items: stretch;
      }
      &.col {
        flex-direction: column;
        align-items: start;
      }
      > img {
        width: calc(100% / 4 - 20px);

        &.double {
          width: calc(100% / 2 - 20px);
        }
      }
    }
    ${media.mLarge`
      max-width: 100%;
      .image-group {
      display: flex;
      /* width: 100%; */
      justify-content: start;
      align-items: start;
      flex-wrap: wrap;
      gap: 10px 10px;

      &.sth {
        align-items: stretch;
      }
      &.col {
        flex-direction: column;
        align-items: start;
      }
      > img {
        flex:2;
        width: calc(100% / 2 - 20px);

        &.double {
          flex:1;
          width: calc(100% / 1 - 20px);
        }
      }
    } 
    `}
  }
`;

export default BizBottom;
