import styled from "styled-components";

export const SubmitButton = styled.button`
  margin-top: 110px;
  padding: 11px 53px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 800;
  background: transparent linear-gradient(107deg, #525ceb 0%, #9f6eeb 100%) 0%
    0% no-repeat padding-box;
`;

export const FormHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 36px;
  > div {
    flex: 1;
  }

  margin-bottom: 15px;
`;

export const FormGroup = ({ label, children }) => {
  return (
    <FormGroupWrap>
      <label>{label}</label>
      {children}
    </FormGroupWrap>
  );
};

const FormGroupWrap = styled.div`
  text-align: left;
  label {
    position: relative;
    display: block;
    color: white;
    padding: 0;
    margin-bottom: 15px;
    font-size: 20;
    font-weight: 800;
    margin-left: 10px;
    color: #525ceb;
    &:before {
      content: "*";
      margin-left: -10px;
      /* left: 10px; */
    }
  }

  > input {
    width: 100%;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    border-top: 0;
    border-bottom: 1px solid #ffffffcc;
    font-size: 15px;
    color: white;
    padding: 10px 0;
    margin-bottom: 35px;
    outline: none;
    &::placeholder {
      color: #ffffffcc;
    }
    &:focus {
      border-right: 0;
      border-left: 0;
      border-top: 0;
      border-bottom: 1px solid white;
    }
    &:active {
      border-right: 0;
      border-left: 0;
      border-top: 0;
      border-bottom: 1px solid white;
    }
  }
`;
