import styled from "styled-components";
import Bg from "../../assets/images/business/bg_01.jpg";
import MainText from "../../assets/images/business/title_01.png";
import { useImgLoadingStatus } from "../hook/LoadingStatus";
import { ImgLoading } from "../elements/loading";

const BizTop = () => {
  const imgLoading = useImgLoadingStatus(Bg);

  if (imgLoading) {
    return <ImgLoading color={"#525ceb"} />;
  }
  return (
    <BizTopWrap $bg={Bg}>
      <img src={MainText} />
    </BizTopWrap>
  );
};

const BizTopWrap = styled.div`
  padding: 255px 0;
  background-image: url(${(props) => props.$bg});
  background-size: cover;
`;
export default BizTop;
