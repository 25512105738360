import { isMobile } from "react-device-detect";
import PFBody from "../components/portf/PFBody";
import PFTop from "../components/portf/PFTop";
import PFTopMobile from "../components/portf/PFTopMobile";
import PFBodyMobile from "../components/portf/PFBodyMobile";
import { Suspense, useEffect } from "react";
import fetchPfData from "../service/history/fetchData";
import styled, { css } from "styled-components";

const Protf = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PfRoot $isMobile={isMobile}>
      <Suspense fallback={<div style={{ height: "100vh" }}></div>}>
        {isMobile ? (
          <PFTopMobile resource={fetchPfData()} />
        ) : (
          <PFTop resource={fetchPfData()} />
        )}
      </Suspense>

      {isMobile ? <PFBodyMobile /> : <PFBody />}
    </PfRoot>
  );
};

const PfRoot = styled.div`
  ${(props) =>
    props.$isMobile &&
    css`
      background-color: black;
      padding-bottom: 1em;
    `}
`;

export default Protf;
