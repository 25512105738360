import styled from "styled-components";

import logo from "../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { media } from "../utils/media";
import { HiMenu } from "react-icons/hi";

const Header = () => {
  const location = useLocation();
  const navi = useNavigate();
  const [menuToggle, setMenuToggle] = useState(false);
  const openMenu = () => setMenuToggle(true);
  const closeMenu = () => setMenuToggle(false);

  const changeMenu_m = (path) => {
    navi(path);
    setMenuToggle(false);
  };
  return (
    <HeaderWrap>
      <div className={"logoArea"}>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className={"menuArea"}>
        <ul>
          <li className={`${location.pathname.endsWith("/") && "active"}`}>
            <Link to="/">
              <span>HOME</span>
            </Link>
          </li>
          <li className={`${location.pathname.endsWith("/about") && "active"}`}>
            <Link to="/about">
              <span>COMPANY</span>
            </Link>
          </li>
          <li
            className={`${
              location.pathname.endsWith("/biz") && "active white"
            }`}
          >
            <Link to="/biz">
              <span>BUSINESS</span>
            </Link>
          </li>
          <li
            className={`${location.pathname.startsWith("/portf") && "active"}`}
          >
            <Link to="/portf">
              <span>PORTFOLIO</span>
            </Link>
          </li>
          {/* <li
            className={`${location.pathname.endsWith("/contact") && "active"}`}
          >
            <span>CONTACT</span>
          </li> */}
        </ul>
      </div>
      <div className={"menu-trip-line"}>
        <HiMenu color="#fff" size={20} onClick={openMenu} />
        <div
          className={`menu-hidden-bg ${menuToggle && "active"}`}
          onClick={closeMenu}
        ></div>
        <div className={`menu-hidden ${menuToggle && "active"}`}>
          <ul>
            <li
              className={"menu-hidden-item"}
              onClick={() => changeMenu_m("/")}
            >
              HOME
            </li>
            <li
              className={"menu-hidden-item"}
              onClick={() => changeMenu_m("/about")}
            >
              COMPANY
            </li>
            <li
              className={"menu-hidden-item"}
              onClick={() => changeMenu_m("/biz")}
            >
              BUSINESS
            </li>
            <li
              className={"menu-hidden-item"}
              onClick={() => changeMenu_m("/portf")}
            >
              PORTFOLIO
            </li>
          </ul>
        </div>
      </div>
    </HeaderWrap>
  );
};

const HeaderWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 111;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding: 41px 160px;

  > .logoArea img {
    display: block;
    max-width: 300px;
    width: 100%;
    cursor: pointer;
  }
  > .menuArea {
    flex: 1;
    > ul {
      list-style: none;
      display: flex;
      justify-content: end;
      gap: 0 40px;

      > li {
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        span {
          /* font-weight: 800; */
          font-size: 18px;
        }
        &:hover {
          color: #525ceb;
        }
        &.active {
          &.white {
            span {
              color: white;
            }
          }
          span {
            font-weight: 800;
          }
          color: #525ceb;
        }
      }
    }
  }

  .menu-trip-line {
    display: none;
  }

  ${media.large`  
      align-items:center;
      padding: 20px 80px;

      > .logoArea img {
          max-width: 200px;
      }

      > .menuArea{
        >ul{
          gap:0 30px;

          >li{
            span{
              font-size:15px;
            }
          }
        }
        
      }
    `}

  ${media.mLarge`
  padding: 20px 20px;
    > .logoArea img {
          max-width: 100px;
    }
    > .menuArea{
        display: none;      
    }
    .menu-trip-line{
      position: relative;
      display: block;

      .menu-hidden{
        position:fixed;
        z-index:11111;
        background: white;
        top:0;
        right:0;
        transform:translateX(200%);
        transition: transform 0.5s ease;
        
        /* padding:10px;
        height: 100vh; */
        
        &.active{
          transform:translateX(0%);
        }
        >ul{
          
          background: transparent linear-gradient(100deg, #0F1A8E 0%, #0F1A8E 0%, #525CEB 49%, #9F6EEB 100%) 0% 0% no-repeat padding-box;;

          position: relative;
          z-index:1111;
          padding:50px 10px 10px 10px;
          height: 100vh;

          box-shadow:#000 0px 0px 20px 5px;
          >li{
            margin-bottom:20px;
            &.menu-hidden-item{
              color:white;
              font-size: 18px;
              font-weight:800;
            }
          }
        }
      }
      .menu-hidden-bg{
          z-index:111;
          background-color:#94949470;
          position: fixed;
          top:0;
          left:0;
          right:0;
          bottom:0;
          width:100%;
          transform:translateX(200%);
          transition: transform 0.5s ease;

          &.active{
            transform: translateX(0);
          }
        }
      
    }
  `}
`;

export default Header;
