import axios from "axios";

const CONTENT_TYPE = "application/json";

export const customAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_PREFIX}`,
  headers: {
    "Content-Type": CONTENT_TYPE,
  },
});
