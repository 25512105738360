import styled from "styled-components";
import TopImage from "../../assets/images/portfolio/img_01.jpg";
import Img1 from "../../assets/images/portfolio/img_02.jpg";
import Img2 from "../../assets/images/portfolio/img_03.jpg";
import Img3 from "../../assets/images/portfolio/img_04.jpg";
import Img4 from "../../assets/images/portfolio/img_05.jpg";
import Img5 from "../../assets/images/portfolio/img_06.jpg";
import Img6 from "../../assets/images/portfolio/img_07.jpg";
import Img7 from "../../assets/images/portfolio/img_08.jpg";
import Img8 from "../../assets/images/portfolio/img_09.jpg";
import { useRecoilValue } from "recoil";
import { pfCategory } from "../../service/recoil/history/category.atom";
import { Suspense } from "react";
import PfBodySuspense from "./PfBodySuspense";
import fetchPVData from "../../service/history/fetchPreview";
const PFImageList_M = [
  {
    id: 1,
    imgSrc: Img1,
    title: "FILTER",
    summary: "FESTIVAL",
  },
  {
    id: 2,
    imgSrc: Img2,
    title: "제주혁신도시",
    summary: "상생발전 뮤직콘서트",
  },
  {
    id: 3,
    imgSrc: Img3,
    title: "토요박물관",
    summary: "산책 개그쇼",
  },
  {
    id: 4,
    imgSrc: Img4,
    title: "피커스제주",
    summary: "가디언즈 오브 그린제주",
  },
  {
    id: 5,
    imgSrc: Img5,
    title: "토요박물관 산책",
    summary: "짙은, 무드트리 공연",
  },
  {
    id: 6,
    imgSrc: Img6,
    title: "토요박물관 산책",
    summary: "버블s의 컬러 버블쇼",
  },
  {
    id: 7,
    imgSrc: Img7,
    title: "토요박물관 산책",
    summary: "동화 음악회 <퍼플엘리>공연",
  },
  {
    id: 8,
    imgSrc: Img8,
    title: "토요박물관 산책",
    summary: "동화 음악회 <퍼플엘리>공연",
  },
];

const PFBodyMobile = () => {
  const cgRecoil = useRecoilValue(pfCategory);

  return (
    <PFBodyWrap>
      <section>
        <Suspense fallback={<div></div>}>
          <PfBodySuspense
            resource={fetchPVData(cgRecoil.label)}
            cgLabel={cgRecoil.label}
          />
        </Suspense>
      </section>
    </PFBodyWrap>
  );
};

const PFBodyWrap = styled.div`
  background-color: #000;
  padding-top: 50px;
`;

export default PFBodyMobile;
