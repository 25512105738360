import styled from "styled-components";

import React from "react";
import BizTop from "../components/biz/BizTop";
import BizMiddle from "../components/biz/BizMiddle";
import BizTech from "../components/biz/BizTech";
import BizBottom from "../components/biz/BizBottom";
import { isMobile } from "react-device-detect";
import BizTechMobile from "../components/biz/BizTechMobile";

const Biz = () => {
  return (
    <React.Fragment>
      <BizTop />
      <BizMiddle />
      {isMobile ? <BizTechMobile /> : <BizTech />}

      <BizBottom />
    </React.Fragment>
  );
};

export default Biz;
