import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import About from "../pages/About";
import Biz from "../pages/Biz";
import Portf from "../pages/Portf";
import PortfDetail from "../pages/PorfDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/biz",
        element: <Biz />,
      },
      {
        path: "/portf",
        element: <Portf />,
      },
      {
        path: "/portf/:id",
        element: <PortfDetail />,
      },
    ],
  },
]);

export default router;
