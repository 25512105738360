import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/fonts/font.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./routes/Router";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
if (container.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    container,
    <HelmetProvider>
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </HelmetProvider>
  );
} else {
  root.render(
    <HelmetProvider>
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </HelmetProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
