import styled, { css } from "styled-components";
import bg4 from "../../assets/images/bg_04.jpg";
import title4 from "../../assets/images/title_04.png";
import title5 from "../../assets/images/title_05.png";
import { RoundedButton } from "../elements/buttons";
import { HiArrowRight, HiPlus } from "react-icons/hi";
import Slider from "react-slick";

import slider1 from "../../assets/images/img_01.jpg";
import slider2 from "../../assets/images/img_02.jpg";
import slider3 from "../../assets/images/img_03.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FormGroup, FormHorizontal, SubmitButton } from "../elements/inputs";
import { media } from "../../utils/media";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Suspense } from "react";
import PfPreviewMain from "../portf/PfPreviewMain";
import fetchPreview from "../../service/history/fetchPreviewData";

const HomeSection4 = () => {
  const navi = useNavigate();

  const movePortfolio = () => {
    navi("portf");
  };
  return (
    <SectionWrap $bg={bg4}>
      <div className={"portfolio"}>
        <p>팝콘엔터테인먼트의 연출 기획을 담은 행사 소개</p>
        <div className={"pf_imgbtn"}>
          <img src={title4} />
          <RoundedButton
            onClick={movePortfolio}
            label={"View more"}
            suffix={<HiPlus />}
            borderColor={"#525CEB"}
            textColor={"#525CEB"}
          />
        </div>
        <div className={"pf_slide"}>
          <Suspense>
            <PfPreviewMain resource={fetchPreview()} />
          </Suspense>
        </div>
      </div>
      <div className={"contactUs"}>
        <p>필수 정보와 문의 내용을 입력해주세요.</p>
        <img src={title5} />
        <div className={"contactFormArea"}>
          {isMobile ? (
            <div className={"textInfo"}>
              <FormGroup label={"제목"}>
                <input type="text" placeholder="제목을 입력하세요." />
              </FormGroup>
              <FormGroup label={"이름"}>
                <input type="text" placeholder="이름을 입력하세요." />
              </FormGroup>
              <FormGroup label={"연락처"}>
                <input type="text" placeholder="연락처를 입력하세요." />
              </FormGroup>
              <FormGroup label={"일정"}>
                <input type="date" />
              </FormGroup>
              <FormGroup label={"문의내용"}>
                <input type="text" placeholder="내용을 간략히 입력하세요." />
              </FormGroup>
              <SubmitButton type="button">
                Send <HiArrowRight />
              </SubmitButton>
            </div>
          ) : (
            <div className={"textInfo"}>
              <FormGroup label={"제목"}>
                <input type="text" placeholder="제목을 입력하세요." />
              </FormGroup>
              <FormGroup label={"이름"}>
                <input type="text" placeholder="이름을 입력하세요." />
              </FormGroup>

              <FormHorizontal>
                <FormGroup label={"연락처"}>
                  <input type="text" placeholder="연락처를 입력하세요." />
                </FormGroup>
                <FormGroup label={"일정"}>
                  <input type="date" />
                </FormGroup>
              </FormHorizontal>
              <FormGroup label={"문의내용"}>
                <input type="text" placeholder="내용을 간략히 입력하세요." />
              </FormGroup>
              <SubmitButton type="button">
                Send <HiArrowRight />
              </SubmitButton>
            </div>
          )}

          <div className={"fileInfo"}></div>
        </div>
      </div>
      <div></div>
    </SectionWrap>
  );
};

const SectionWrap = styled.div`
  position: relative;
  padding: 222px 260px;
  background-image: url(${(props) => props.$bg});
  background-size: cover;

  ${media.large`
  padding:180px 130px;
  `}

  ${media.mLarge`
  padding:100px 50px;
  `}

  .portfolio {
    .pf_imgbtn {
      display: flex;
      justify-content: space-between;
      align-items: end;
      padding-bottom: 184px;
      > img {
        display: block;
        max-width: 744px;
      }

      ${media.large`
      display: block;
      >img{
        margin-bottom:20px;
        width: calc(100% - 260px);
      }
      >button{
        
      }
      `}

      ${media.mLarge`
      >img{
        width: 100%;
        margin-bottom:40px;
      }
      `}
    }
    > p {
      color: #525ceb;
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 40px;
    }
    .pf_slide {
      .pf_slide_mobile {
        display: flex;
        flex-direction: column;
      }
      /* display: flex;
      justify-content: start;
      align-items: center; */
    }
  }

  .contactUs {
    margin-top: 300px;
    text-align: center;
    > p {
      color: #525ceb;
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 40px;
      margin: 0;
    }
    > img {
      /* display: block; */
      max-width: 415px;
    }
    .contactFormArea {
      width: calc(100% - 260px);
      margin: 80px auto;
      padding: 100px;
      border: 3px solid #525ceb;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      .textInfo {
        flex: 1;
      }
      .fileInfo {
        flex: 1;
      }
    }

    ${media.mLarge`
    >img{
      max-width:100%;
    }
    .contactFormArea{
        width:100%;
        padding:50px;
        
      }

    `}
  }
`;

export default HomeSection4;
