import { useEffect, useState } from "react";

export const useImgLoadingStatus = (imgSrc) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const image = new Image();
    image.src = imgSrc;
    image.onload = () => setLoading(false);
  }, [imgSrc]);

  return loading;
};
