function fetchCompany() {
  let aboutUsList = [];

  const suspender = fetch(`${process.env.REACT_APP_API_PREFIX}/cp/aboutus`)
    .then((response) => response.json())
    .then((data) => (aboutUsList = data));

  return {
    read() {
      if (aboutUsList.length == 0) {
        throw suspender;
      } else {
        return aboutUsList;
      }
    },
  };
}

function fetchCompanyHistory() {
  let histories = [];
  const suspender = fetch(`${process.env.REACT_APP_API_PREFIX}/cp/history`)
    .then((response) => response.json())
    .then((data) => (histories = data));

  return {
    read() {
      if (histories.length == 0) {
        throw suspender;
      } else {
        return histories;
      }
    },
    refresh() {
      return suspender;
    },
  };
}

function fetchCData() {
  return {
    aboutus: fetchCompany(),
    history: fetchCompanyHistory(),
  };
}

export default fetchCData;
