import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const PfBodySuspense = ({ resource, cgLabel }) => {
  const previewList = resource.previewList.read();

  if (isMobile) {
    return previewList.map((e, i) => (
      <div key={`${cgLabel}_${e.id}`} style={{ marginBottom: "1em" }}>
        <Link to={`/portf/${e.id}`}>
          <img src={e.thumbnail} />
        </Link>
      </div>
    ));
  }

  return (
    <ul>
      {previewList.map((e, i) => (
        <li key={`${cgLabel}_${e.id}`}>
          <Link to={`/portf/${e.id}`}>
            <img src={e.thumbnail} />
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default PfBodySuspense;
