import mainBg1 from "../assets/images/bg_01.jpg";
import HomeSection1 from "../components/homeSection/HomeSection1";
import HomeSection2 from "../components/homeSection/HomeSection2";
import HomeSection3 from "../components/homeSection/HomeSection3";
import HomeSection4 from "../components/homeSection/HomeSection4";
import HelmetMeta from "../utils/helmet";

const Home = () => {
  return (
    <div>
      <HelmetMeta
        description={
          "불가능을 가능으로, 변화에 발빠르게 적응하고 끊임없는 의문을 던지다."
        }
        title={"TOGETHER TOMORROW 팝콘 엔터테인먼트"}
        // imagesrc={}
        // url={"http://"}
      />
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      <HomeSection4 />
    </div>
  );
};

export default Home;
