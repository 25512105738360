import styled from "styled-components";
import { ReactComponent as LeftIcon } from "../../assets/icons/arrow_right_03.svg";
import { Suspense, useState } from "react";
import { PFNavItem } from "../elements/pfNav";
import MainThumbnail from "../../assets/images/portfolio/img_01.jpg";
import { Link } from "react-router-dom";
import { media } from "../../utils/media";
import PFTopItem from "./PFTopItem";
import fetchPfData from "../../service/history/fetchData";
import { useRecoilState } from "recoil";
import { pfCategory } from "../../service/recoil/history/category.atom";

const PFTop = ({ resource }) => {
  const [cgRecoil, setCgRecoil] = useRecoilState(pfCategory);
  const changeIndex = (index, label) => {
    setCgRecoil({ label: label, value: index });
  };
  const codeList = resource.codeList.read();
  const thumbList = resource.thumbList.read();

  const createMainThumb = (index) => {
    const thumbInfo =
      thumbList[
        index === 0 ? "ALL" : codeList.find((e) => e.pfOrd === index).pfCode
      ];

    return (
      <Link to={`/portf/${thumbInfo.id}`}>
        <img src={thumbInfo.src} />
      </Link>
    );
  };

  return (
    <Wrap className="bg-[#000000]">
      {/* sub navi */}
      <section className={"pf-nav"}>
        {/* nav-title */}
        <div className={"pf-nav-title"}>
          <p className={"main-text main-text-white"}>ALL WORKS</p>
          <div
            className="flex justify-start items-center"
            style={{ gap: "0 20px" }}
          >
            <p className={"main-text main-text-blue"}>IN HERE</p>
            <LeftIcon width={45} height={45} />
          </div>
        </div>
        {/* nav-items */}
        <div className={"pf-nav-items"}>
          <ul>
            <PFNavItem
              label={"ALL"}
              index={0}
              code={"ALL"}
              curIndex={cgRecoil.value}
              onClick={changeIndex}
            />
            {codeList.map((e, index) => (
              <PFNavItem
                key={"pf_code_" + index}
                label={e.pfCodeKr}
                index={e.pfOrd}
                code={e.pfCode}
                curIndex={cgRecoil.value}
                onClick={changeIndex}
              />
            ))}
          </ul>
        </div>
      </section>
      {/* main thumbnail */}
      <section className={"pf-thumbnail"}>
        {createMainThumb(cgRecoil.value)}
      </section>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
  padding: 255px 0;
  display: flex;
  justify-content: space-between;
  align-items: start;

  ${media.mLarge`
  padding:100px 0;
  `}

  > section {
    max-width: calc((100% / 2) - 260px);
    margin-left: auto;
    flex: 1;

    ${media.mLarge`
    max-width:100%;
    padding:0 25px;
    
    `}

    &.pf-nav {
      text-align: left;

      .pf-nav-title {
        p {
          font-family: "SB font";
          &.main-text {
            font-size: 70px;
            font-weight: bold;
            &.main-text-white {
              color: #ffffff;
            }
            &.main-text-blue {
              color: #525ceb;
            }
          }
        }
      }

      .pf-nav-items {
        margin-top: 123px;
        color: white;

        > ul {
          > li {
            padding-bottom: 25px;
            > span {
              cursor: pointer;
              font-family: "SB font";
              font-size: 25px;
              font-weight: 300;
              color: #949494;
              transition: all 0.5s ease;
            }

            &.active {
              > span {
                position: relative;
                margin-left: 20px;
                color: white;
                font-weight: bold;
                &::before {
                  position: absolute;
                  content: "|";
                  left: -20px;
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }
    &.pf-thumbnail {
      padding-top: 133px;
      text-align: left;
      margin-right: auto;
      margin-left: unset;
    }
  }
`;

export default PFTop;
