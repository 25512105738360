import styled from "styled-components";
import { ReactComponent as CorpName } from "../../assets/icons/corp_name.svg";
import { ReactComponent as HeaderName } from "../../assets/icons/header_name.svg";
import { ReactComponent as CreateDate } from "../../assets/icons/create_date.svg";
import { ReactComponent as Contents } from "../../assets/icons/contents.svg";
import { ReactComponent as Maps } from "../../assets/icons/maps.svg";
import { ReactComponent as Contact } from "../../assets/icons/contacts.svg";
import { media } from "../../utils/media";

const ICON_LIST = [
  <CorpName />,
  <HeaderName />,
  <CreateDate />,
  <Contents />,
  <Maps />,
  <Contact />,
];
const AboutUsItem = ({ resource }) => {
  const aboutUsListe = resource.aboutus.read();
  return [0, 1, 2, 3, 4, 5].map((e) => (
    <AboutItem key={`about_${e}`}>
      {ICON_LIST[e]}
      <div className={"ai-item"}>
        <p className={"title"}>{aboutUsListe[e].abTypeKor}</p>
        {/* 전화번호는 전화 + 이메일 을 같이 노출 시킴 */}
        {e !== 5 ? (
          // 전화번호가 아닐 경우
          <span>{aboutUsListe[e].abValue}</span>
        ) : (
          // 전화번호일 경우
          <div className="flex flex-col" style={{ gap: "10px 0" }}>
            <span>{`전화 | ${aboutUsListe[e].abValue}`}</span>
            <span>{`이메일 | ${aboutUsListe[e + 1].abValue}`}</span>
          </div>
        )}
      </div>
    </AboutItem>
  ));
};

export default AboutUsItem;

const AboutItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  > div.ai-item {
    padding-top: 55px;
    text-align: center;

    p {
      line-height: 1;
      &.title {
        font-size: 30px;
        font-weight: bold;
        padding-bottom: 25px;
        color: #ffffff;

        ${media.large`
          font-size:25px;
      `}

        ${media.mLarge`
      font-size:20px;
      `}
      }
    }

    span {
      display: block;
      line-height: 1;
      font-size: 20px;
      font-weight: normal;
      color: #ffffff;

      ${media.large`
        font-size:15px;
      `}

      ${media.mLarge`
        font-size:12px;
      `}
    }
  }
`;
