function fetchPreviewMain() {
  let previewList = [];

  const suspender = fetch(`${process.env.REACT_APP_API_PREFIX}/pf/preview/main`)
    .then((response) => response.json())
    .then((data) => (previewList = data));

  return {
    read() {
      if (previewList.length === 0) {
        throw suspender;
      } else {
        return previewList;
      }
    },
  };
}

function fetchPreview() {
  return {
    previewList: fetchPreviewMain(),
  };
}

export default fetchPreview;
