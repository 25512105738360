import styled from "styled-components";
import Img1 from "../../assets/images/portfolio/img_11.jpg";
import Img2 from "../../assets/images/portfolio/img_12.jpg";
import Img3 from "../../assets/images/portfolio/img_13.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect, useState } from "react";

const PFDetailFooter = () => {
  const [curIndex, setCurIndex] = useState(0);
  useEffect(() => {
    setCurIndex(0);
  }, []);

  const settings = {
    infinite: false,
    slidesToShow: 1,
    variableWidth: true,
    // variableHeight: true,
    arrows: false,
    slidesToScroll: 1,
    beforeChange: (prev, current) => setCurIndex(current),
  };

  return (
    <PFDetailWrap>
      <p className={"footer-title"}>PHOTO VIEW</p>
      <div className={"list-scroll"}>
        <div className={"list-scroll-image"}>
          <Slider {...settings}>
            <img src={Img1} />
            <img src={Img2} />
            <img src={Img3} />
          </Slider>
        </div>
        <div className={"list-scroll-indicator"}>
          <PFSlideIndicator $length={3} $curIndex={curIndex} />
        </div>
      </div>
    </PFDetailWrap>
  );
};

const PFDetailWrap = styled.div`
  padding-top: 100px;
  color: white;

  max-width: calc(100% - 260px);
  margin-left: auto;

  > p.footer-title {
    position: relative;
    display: inline-block;
    font-family: "SB font";
    font-size: 30px;
    letter-spacing: -0.75px;
    &::after {
      position: absolute;
      content: "|";
      right: -50px;
      font-family: "";
      font-weight: 300;
    }
  }

  > .list-scroll {
    padding: 50px 0;
    .list-scroll-image {
      padding-bottom: 50px;
      /* display: flex;
      flex-wrap: nowrap;
      justify-content: start;
      align-items: center; */
      /* overflow-x: scroll; */

      img {
        max-width: 576px;
        margin-right: 50px;
      }
    }

    .list-scroll-indicator {
      margin-top: 50px;
      position: relative;
      background-color: #949494;
      /* padding: 10px 0; */
      height: 3px;
    }
  }
`;

const PFSlideIndicator = styled.div`
  position: absolute;
  display: block;
  z-index: 1111;
  top: 0;
  bottom: 0;
  width: calc(100% / ${(props) => props.$length});
  height: 3px;
  color: white;
  background-color: white;
  transform: translateX(${(props) => props.$curIndex * 100 + "%"});
  transition: transform 0.5s ease;
`;

export default PFDetailFooter;
