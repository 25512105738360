import styled from "styled-components";
import { media } from "../../utils/media";
import { TermsData } from "./termsData";

export const TemrsModal = ({ open, close, type }) => {
  const onClickBackground = (e) => {
    if (e.target !== e.currentTarget) return;
    close();
  };

  if (!open) return;

  return (
    <ModalWrap $open={open} onClick={onClickBackground}>
      <div className={"modal-contents"}>
        <div className={"modal-header"}>
          <span>{TermsData[type]?.title}</span>
          <span className={"modal-close"} onClick={close}>
            &times;
          </span>
        </div>
        <div className={"modal-body"}>
          {/* <p className="modal-item-title">이메일 무단 수집 거부</p> */}
          <ul className={"modal-item-list"}>
            {TermsData[type]?.list.map((e, i) => (
              <li
                key={TermsData[type]?.key + "_" + i}
                className={`modal-item-list-item ${e.isBold ? "bold" : ""} ${
                  e.isSubItem ? "subItem" : ""
                }`}
              >
                {e.text}
              </li>
            ))}
            {/* <li className={"modal-item-list-item"}>
              본 웹 사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그
              밖의 기술적 장치를 이용하여 "무단으로 수집되는 것을 거부"하며,
              이를 위반시 정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.
            </li>

            <li className={"modal-item-list-item"}>
              누구든지 전자우편주소의 수집을 거부하는 의사가 명시된 인터넷
              홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램 그 밖의
              기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.
            </li>
            <li className={"modal-item-list-item"}>
              누구든지 제 1항의 규정을 위반하여 수집된 전자우편주소를
              판매·유통하여서는 아니된다.
            </li>
            <li className={"modal-item-list-item"}>
              누구든지 제 1항 및 제 2항의 규정에 의하여 수집·판매 및 유통이
              금지된 전자우편주소임을 알고 이를 정보전송에 이용하여서는
              아니된다.
            </li>
            <li className={"modal-item-list-item bold"}>담당자 : 민정현</li>
            <li className={"modal-item-list-item bold"}>
              전화번호 : 010-4647-5556
            </li>
            <li className={"modal-item-list-item bold"}>
              게시일 : 2024년 06월 12일
            </li> */}
          </ul>
        </div>
      </div>
    </ModalWrap>
  );
};

const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #ebebeb8a;
  display: ${(props) => (props.$open ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  .modal-contents {
    position: relative;
    background-color: #fff;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 1em 2em;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1em;
      margin-bottom: 1em;
      border-bottom: 1px solid #ebebeb;

      .modal-close {
        cursor: pointer;
      }
    }

    .modal-body {
      .modal-item-title {
        font-weight: bold;
      }
      .modal-item-list {
        margin: 1em 2em;
        list-style: decimal;
        max-height: 500px;
        overflow-y: scroll;

        .modal-item-list-item {
          padding: 1em 0;
          word-break: keep-all;

          &.bold {
            font-weight: bold;
          }
          &.subItem {
            padding-left: 1em;
          }
        }
      }
    }

    ${media.mLarge`
    max-width:unset;
    width:90%;
    padding:1em 1em;
    font-size:15px;
    .modal-header {
      padding-bottom: 1em;
      margin-bottom: 1em;
      border-bottom: 1px solid #ebebeb;
    }

    .modal-body {
      .modal-item-title {
        font-weight: bold;
      }
      .modal-item-list {
        margin: 1em 0 1em 0;
        padding-right:1em;
        list-style: decimal;
        max-height: 200px;
        overflow-y: scroll;

        .modal-item-list-item {
          padding: 1em 0;
          word-break: keep-all;

          &.bold {
            font-weight: bold;
          }
        }
      }
    }
        `}
  }
`;
