function fetchCode() {
  let codeList = [];

  const suspender = fetch(`${process.env.REACT_APP_API_PREFIX}/pf/category`)
    .then((response) => response.json())
    .then((data) => (codeList = data));

  return {
    read() {
      if (codeList.length == 0) {
        throw suspender;
      } else {
        return codeList;
      }
    },
  };
}
function fetchCategoryThumb() {
  const resultMap = {
    flag: false,
    data: {},
  };
  const suspender = fetch(
    `${process.env.REACT_APP_API_PREFIX}/pf/category/thumb`
  )
    .then((response) => response.json())
    .then((data) => {
      resultMap.flag = true;
      resultMap.data = data;
    });

  return {
    read() {
      if (!resultMap.flag) {
        throw suspender;
      } else {
        return resultMap.data;
      }
    },
  };
}
function fetchItemList() {
  let thumbList = [];
  const suspender = (_code) =>
    fetch(`${process.env.REACT_APP_API_PREFIX}/pf/preview?code=${_code}`)
      .then((response) => response.json())
      .then((data) => (thumbList = data));

  return {
    init() {
      throw suspender("all");
    },
    read(code) {
      if (thumbList.length === 0) {
        throw suspender(code);
      } else {
        return thumbList;
      }
    },
  };
}

function fetchPfData() {
  return {
    codeList: fetchCode(),
    thumbList: fetchCategoryThumb(),
    // itemList: fetchItemList(),
  };
}

export default fetchPfData;
