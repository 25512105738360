import styled from "styled-components";
import Img1 from "../../assets/images/portfolio/img_02.jpg";
import Img2 from "../../assets/images/portfolio/img_03.jpg";
import Img3 from "../../assets/images/portfolio/img_04.jpg";
import Img4 from "../../assets/images/portfolio/img_05.jpg";
import Img5 from "../../assets/images/portfolio/img_06.jpg";
import Img6 from "../../assets/images/portfolio/img_07.jpg";
import Img7 from "../../assets/images/portfolio/img_08.jpg";
import Img8 from "../../assets/images/portfolio/img_09.jpg";
import Img9 from "../../assets/images/portfolio/img_10.jpg";
import Img10 from "../../assets/images/portfolio/img_11.jpg";
import Img11 from "../../assets/images/portfolio/img_12.jpg";
import Img12 from "../../assets/images/portfolio/img_13.jpg";
import { useRecoilState, useRecoilValue } from "recoil";
import { pfCategory } from "../../service/recoil/history/category.atom";
import { Suspense } from "react";
import fetchPVData from "../../service/history/fetchPreview";
import PfBodySuspense from "./PfBodySuspense";
import { PulseLoader } from "react-spinners";

const PFImageList = [
  {
    id: 1,
    imgSrc: Img1,
    title: "FILTER",
    summary: "FESTIVAL",
  },
  {
    id: 2,
    imgSrc: Img2,
    title: "제주혁신도시",
    summary: "상생발전 뮤직콘서트",
  },
  {
    id: 3,
    imgSrc: Img3,
    title: "토요박물관",
    summary: "산책 개그쇼",
  },
  {
    id: 4,
    imgSrc: Img4,
    title: "피커스제주",
    summary: "가디언즈 오브 그린제주",
  },
  {
    id: 5,
    imgSrc: Img5,
    title: "토요박물관 산책",
    summary: "짙은, 무드트리 공연",
  },
  {
    id: 6,
    imgSrc: Img6,
    title: "토요박물관 산책",
    summary: "버블s의 컬러 버블쇼",
  },
  {
    id: 7,
    imgSrc: Img7,
    title: "토요박물관 산책",
    summary: "동화 음악회 <퍼플엘리>공연",
  },
  {
    id: 8,
    imgSrc: Img8,
    title: "토요박물관 산책",
    summary: "동화 음악회 <퍼플엘리>공연",
  },
];

const PFBody = () => {
  const cgRecoil = useRecoilValue(pfCategory);
  console.log(cgRecoil);
  return (
    <PFWrap>
      <Suspense
        fallback={
          <ul>
            <li>
              <PulseLoader />
            </li>
          </ul>
        }
      >
        <PfBodySuspense
          resource={fetchPVData(cgRecoil.label)}
          cgLabel={cgRecoil.label}
        />
      </Suspense>
      {/* <ul>
        {PFImageList.map((data, index) => (
          <li key={data.id}>
            <img src={data.imgSrc} />
          </li>
        ))}
      </ul> */}
      <div className={"pf-paging"}></div>
    </PFWrap>
  );
};

const PFWrap = styled.div`
  background-color: #000;

  > ul {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    max-width: calc(100% - (260px * 2));
    margin: 0 auto;
    gap: 50px 40px;

    > li {
      max-width: calc((100% / 4) - 40px);
    }
  }

  .pf-paging {
    padding: 90px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default PFBody;
