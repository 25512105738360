import styled from "styled-components";
import mainBg1 from "../../assets/images/bg_01.jpg";
import title1 from "../../assets/images/title_01.png";
import { RoundedButton } from "../elements/buttons";
import { HiArrowRight } from "react-icons/hi";
import { useEffect, useState } from "react";
import { ImgLoading } from "../elements/loading";
import { useImgLoadingStatus } from "../hook/LoadingStatus";
import { media } from "../../utils/media";

const HomeSection1 = () => {
  const isImgLoading = useImgLoadingStatus(mainBg1);

  if (isImgLoading) {
    return <ImgLoading />;
  }
  return (
    <SectionWrap>
      <img src={mainBg1} />
      <div className="titleArea">
        <img src={title1} />
        <RoundedButton
          type={"button"}
          label={"Showing"}
          suffix={<HiArrowRight />}
        />
      </div>
    </SectionWrap>
  );
};

const SectionWrap = styled.div`
  position: relative;
  > img {
    display: block;
    width: 100%;
  }

  > .titleArea {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 51px 0;
    > img {
      display: block;
      width: 100%;
      max-width: 819px;
    }
  }

  ${media.large`
  > .titleArea{
    >img{
      max-width:600px;
    }
  }
  `}

  ${media.mLarge`
    >img{
      object-fit:cover;
      height: 100vh;
    }
    > .titleArea{
      >img{
        max-width:100%;
        width: 80%;
      }

      
    }
  `}
`;

export default HomeSection1;
