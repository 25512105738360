function fetchDetail(id) {
  const detail = {
    flag: false,
    data: {},
  };

  const suspender = fetch(
    `${process.env.REACT_APP_API_PREFIX}/pf/item?id=${id}`
  )
    .then((response) => response.json())
    .then((data) => {
      detail.flag = true;
      detail.data = data;
    });

  return {
    read() {
      if (!detail.flag) {
        throw suspender;
      } else {
        return detail.data;
      }
    },
  };
}

function fetchDetailData(id) {
  return {
    detail: fetchDetail(id),
  };
}

export default fetchDetailData;
