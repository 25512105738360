import styled from "styled-components";
import { media } from "../../utils/media";
import { useEffect } from "react";
import { getHistories } from "../../service/history/api";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";

/**
 * 회사 history componenet
 * year, title, summary, imgSrc, histories
 * @param {*} list
 * @returns
 */
const AboutHistory = ({ resource }) => {
  const history = resource.history.read();
  useEffect(() => {
    // getHistories().then((data) => {
    //   console.log("success!!");
    //   console.log(data);
    // });
  }, []);

  if (isMobile) {
    return (
      <Wrap>
        <p className={"section_title text-mainblue"}>CORPORATE HISTORY</p>
        <Slider>
          {history.map((e, index) => (
            <div
              key={"ah_" + index}
              className={`flex justify-between align-top pb-[202px] pr-[10px]`}
              style={{ gap: "0 20px" }}
            >
              {/* history text area  */}
              <div
                className={`history_text flex flex-col justify-start items-stretch`}
              >
                <p className={"card_year"}>{e.hyear}</p>
                <p className={"card_title"}>{e.htitle}</p>
                <span className={`card_summary `}>{e.hsubTitle}</span>
              </div>
              {/* history contents area -  data loop */}
              <div className={"history_contents"}>
                <div className={"hc-thumbnail"}>
                  <img src={e.himgUrl} />
                </div>
                <ul>
                  {/* loop require */}
                  {e.items.map((h, i) => (
                    <li
                      key={`${e.id}_${h.id}_history`}
                      className={"flex justify-start align-top"}
                    >
                      <label>
                        {h.hmonth.length < 2 ? "0" + h.hmonth : h.hmonth}
                      </label>
                      <span>{h.hcomments}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </Slider>
      </Wrap>
    );
  }
  return (
    <Wrap>
      <p className={"section_title text-mainblue"}>CORPORATE HISTORY</p>
      {history.map((e, index) => (
        <div
          key={"ah_" + index}
          className={`flex ${
            index % 2 && "flex-row-reverse"
          } justify-between align-top pb-[202px]`}
          style={{ gap: "0 20px" }}
        >
          {/* history text area  */}
          <div
            className={`history_text flex flex-col justify-start items-stretch`}
            style={{ flexBasis: "min-content" }}
          >
            <p className={"card_year"}>{e.hyear}</p>
            <p className={"card_title"}>{e.htitle}</p>
            <span className={`card_summary `}>{e.hsubTitle}</span>
          </div>
          {/* history contents area -  data loop */}
          <div className={"history_contents"}>
            <div className={"hc-thumbnail"}>
              <img src={e.himgUrl} />
            </div>
            <ul>
              {/* loop require */}
              {e.items.map((h) => (
                <li className={"flex justify-start align-top"}>
                  <label>
                    {h.hmonth.length < 2 ? "0" + h.hmonth : h.hmonth}
                  </label>
                  <span>{h.hcomments}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </Wrap>
  );
};

const Wrap = styled.div`
  padding-top: 116px;

  width: 1400px;
  margin: 0 auto;

  ${media.large`
    width:1024px;
  `}

  ${media.medium`
    width:768px;
  `}

  ${media.mLarge`
  width:calc(100% - 50px);
  margin:0 25px;
  `}

  p {
    line-height: 1;
    &.section_title {
      position: relative;
      display: inline-block;
      font-size: 30px;
      font-weight: bold;
      font-family: "SB font";
      padding-bottom: 100px;

      &::after {
        position: absolute;
        content: "|";
        right: -50px;
        font-weight: normal;
      }

      ${media.large`
        font-size:25px;
      `}

      ${media.medium`
        font-size:20px;
      `}
    }
    &.card_year {
      width: 419px;
      display: inline-block;
      font-family: "SB font";
      font-size: 150px;
      letter-spacing: -7.5px;
      margin-bottom: 40px;
      ${media.large`

      width: 380px;
        font-size:120px;
      `}

      ${media.medium`
        width:340px;
        font-size:100px;
      `}
    }
    &.card_title {
      display: inline-block;
      font-size: 40px;
      letter-spacing: 0px;
      font-weight: bold;
      margin-bottom: 40px;

      ${media.large`
        font-size:30px;
      `}
      ${media.medium`
        font-size:25px;
      `}
    }
  }
  span {
    &.card_summary {
      font-weight: normal;
      font-size: 20;
      line-height: 2;
    }

    ${media.large`
        font-size:18px;
      `}

    ${media.large`
        font-size:16px;
      `}
  }

  .history_contents {
    max-width: 700px;
    width: 100%;

    .hc-thumbnail {
      padding-bottom: 100px;
      > img {
        width: 100%;
      }

      ${media.large`
      padding-bottom:80px;
      `}

      ${media.medium`
      padding-bottom:60px;
      `}
    }

    ${media.large`
    max-width:500px;
    `}

    ${media.medium`
    max-width:350px;
    `}

    > ul {
      > li {
        gap: 0 100px;
        padding-bottom: 50px;
        > label {
          font-size: 20px;
          font-weight: bolder;
        }
        > span {
          font-size: 20px;
          font-weight: normal;
        }

        ${media.large`
          gap:0 50px;
          padding-bottom: 40px;
          >label,span{
            font-size:15px;
          }
          
        `}
        ${media.large`
          gap:0 30px;
          padding-bottom: 30px;
          >label,span{
            font-size:12px;
          }
          
        `}
      }
    }
  }
`;

export default AboutHistory;
