import styled from "styled-components";
import logoImg from "../assets/images/logo.png";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { TemrsModal } from "./modal/termModal";

const Footer = () => {
  const [modalOpen, setModalOpen] = useState({
    toggle: false,
    type: "",
  });

  useEffect(() => {
    setModalOpen({
      toggle: false,
      type: "",
    });
  }, []);

  const toggleModal = (type) =>
    setModalOpen({
      toggle: true,
      type: type,
    });
  const untoggleModal = () =>
    setModalOpen({
      toggle: false,
      type: "",
    });
  if (isMobile) {
    return (
      <>
        <div className="bg-mainblue p-csm text-white">
          <div className={"flex justify-between align-top"}>
            <div className={"flex flex-col"} style={{ gap: "45px 0" }}>
              <img src={logoImg} width={200} />
              <ul
                className={"text-[13px] text-right"}
                style={{ gap: "10px 0" }}
              >
                <li className={"pb-[10px]"}>
                  <a
                    onClick={() => toggleModal("use")}
                    style={{ cursor: "pointer" }}
                  >
                    이용약관
                  </a>
                </li>
                {/* <li className={"pb-[10px]"}>
                  <a onClick={() => toggleModal("personal")}>
                    개인정보처리방침
                  </a>
                </li> */}

                <li className={"pb-[10px]"}>
                  <a
                    onClick={() => toggleModal("email")}
                    style={{ cursor: "pointer" }}
                  >
                    이메일무단수집거부
                  </a>
                </li>
              </ul>
              <div className={"flex flex-col"} style={{ gap: "13px 0" }}>
                <ul className={" font-light text-[13px]"}>
                  <li className={"pb-[5px]"}>㈜팝콘엔터테인먼트 대표 강다윤</li>
                  <li className={"pb-[5px]"}>사업자 등록번호 643-81-02221</li>
                  <li className={"pb-[5px]"}>
                    제주특별자치도 제주시 금월길 105
                  </li>
                  <li className={"pb-[5px]"}>Tel : 010-4676-5556</li>
                  <li className={"pb-[5px]"}>Fax : 0504-178-5556</li>
                  <li className={"pb-[5px]"}>Jejupopcorn@gmail.com</li>
                </ul>
              </div>

              <span className={"text-[13px] font-light"}>
                ⓒ POPCORN ENTERTAINMENT, INC. All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
        <TemrsModal
          type={modalOpen.type}
          open={modalOpen.toggle}
          close={untoggleModal}
        />
      </>
    );
  }

  return (
    <>
      <div className="bg-mainblue p-cmd text-white">
        <div className={"flex justify-between align-top"}>
          <div className={"flex flex-col"} style={{ gap: "45px 0" }}>
            <img src={logoImg} width={200} />
            <div className={"flex flex-col"} style={{ gap: "13px 0" }}>
              <ul className={"flex font-light text-[13px]"}>
                <li>㈜팝콘엔터테인먼트 대표 강다윤 ㅣ </li>
                <li>사업자 등록번호 643-81-02221 ㅣ </li>
                <li>제주특별자치도 제주시 금월길 105</li>
              </ul>
              <ul className={"flex font-light text-[13px]"}>
                <li>Tel : 010-4676-5556 ㅣ </li>
                <li>Fax : 0504-178-5556 ㅣ </li>
                <li>Jejupopcorn@gmail.com</li>
              </ul>
            </div>
          </div>
          <div className={"flex flex-col justify-between text-right"}>
            <ul
              className={"flex justify-end align-middle text-[13px]"}
              style={{ gap: "0 10px" }}
            >
              <li>
                <a
                  onClick={() => toggleModal("use")}
                  style={{ cursor: "pointer" }}
                >
                  이용약관
                </a>
              </li>
              {/* <li>ㅣ</li>
              <li>
                <a onClick={() => toggleModal("personal")}>개인정보처리방침</a>
              </li> */}
              <li>ㅣ</li>

              <li>
                <a
                  onClick={() => toggleModal("email")}
                  style={{ cursor: "pointer" }}
                >
                  이메일무단수집거부
                </a>
              </li>
            </ul>
            <span className={"text-[13px] font-light"}>
              ⓒ POPCORN ENTERTAINMENT, INC. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
      <TemrsModal
        type={modalOpen.type}
        open={modalOpen.toggle}
        close={untoggleModal}
      />
    </>
  );
};

export default Footer;
