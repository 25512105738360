import styled from "styled-components";
import mainBg2 from "../../assets/images/bg_02.jpg";
import title2 from "../../assets/images/title_02.png";
import { RoundedButton } from "../elements/buttons";
import { HiArrowRight } from "react-icons/hi";
import { media } from "../../utils/media";
import { useNavigate } from "react-router-dom";

const HomeSection2 = () => {
  const navi = useNavigate();

  const moveCompany = () => {
    navi("/about");
  };
  return (
    <SectionWrap>
      <img src={mainBg2} />
      <div className={"contentArea"}>
        <div className={"contentTop"}>
          <p className={"guideText"}>
            불가능을 가능으로, 변화에 발빠르게 적응하고 끊임없는 의문을 던지다.
          </p>
          <img src={title2} />
        </div>
        <div className={"contentMiddle"}>
          <p className="summaryText">
            "가능성에 초점을 둔, 끊임없는 의문과 검증. 풍부한 경험 기반 독창적
            기획과 진행력. 탄탄한 토대위에 쌓아올린 이상적인 솔루션. PCN ent 의
            자부심입니다."
          </p>
          <p className={"normalText"}>
            변화에 두려워하지 않고 직면하고 탐구하며, 해답을 도출하여 이상을
            실현합니다. 니즈를 캐치한 후, 발빠른 실행력으로 최고의 솔루션을
            제안합니다. 독창적 컨설팅과 전문 체계화된 프로세스를 제공하고, 보다
            나아간 그 이상의 가치를 실현합니다.
          </p>
          <RoundedButton
            onClick={moveCompany}
            label={"About us"}
            suffix={<HiArrowRight />}
            borderColor={"#525CEB"}
            textColor={"#525CEB"}
          />
        </div>
      </div>
    </SectionWrap>
  );
};

const SectionWrap = styled.div`
  position: relative;

  > img {
    display: block;
    width: 100%;

    ${media.mLarge`
    object-fit:cover;
    min-height: 100vh;
    height: 100%;
    `}
  }
  > .contentArea {
    width: calc(100% - 260px * 2);
    margin: 0 auto;
    /* padding: 0 260px; */
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    ${media.large`
    width: calc(100% - 130px * 2);
    
    `}

    ${media.mLarge`
    width: calc(100% - 50px);
    `}

    > .contentTop {
      padding-bottom: 250px;

      > p.guideText {
        font-size: 20px;
        font-weight: bold;
        color: #525ceb;
        margin-bottom: 39px;
      }
      > img {
        max-width: 814px;
        display: block;
      }

      ${media.large`
      padding-bottom: 100px;
      > p.guideText {
        font-size: 18px;
        
      }
      > img{
        width:calc(100% - 260px);
      }
      `}

      ${media.mLarge`
        padding-top:100px;
        display: flex;
        flex-direction:column;

        >p.guideText{
          word-break:keep-all;
          /* margin-bottom:20px; */
        }
        >img{
          max-width:100%;
          width:100%;
        }
        
      `}
    }

    > .contentMiddle {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: end;

      p {
        max-width: 558px;
        &.summaryText {
          color: #525ceb;
          font-size: 25px;
          font-weight: 800;
          padding-bottom: 60px;
        }
        &.normalText {
          color: white;
          font-size: 15px;
          padding-bottom: 40px;
        }
      }

      ${media.large`
      p{
        max-width: 400px;
        &.summaryText {
          color: #525ceb;
          font-size: 20px;
          font-weight: 800;
          padding-bottom: 60px;
        }
        &.normalText {
          color: white;
          font-size: 13px;
          padding-bottom: 40px;
        }
      }
      `}

      ${media.mLarge`
      p{
        max-width: 400px;
        &.summaryText {
          color: #525ceb;
          font-size: 20px;
          font-weight: 800;
          padding-bottom: 60px;
        }
        &.normalText {
          color: white;
          font-size: 13px;
          padding-bottom: 10px;
        }
      } 
      `}
    }
  }
`;

export default HomeSection2;
